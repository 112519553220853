export enum SkyRotationNormalization {
  southToNorth = '0 180 0',
  eastToNorth = '0 90 0',
  westToNorth = '0 -90 0',
  tiltedLeftStand = '0 63 0',
  tiltedRightStand = '0 -63 0',
}

export type FileName = string;
export type FileNames = Record<string, FileName>;

export const ArrowPosition = {
  Up: (r: number) => `-${r} -1 0`,
  Down: (r: number) => `${r} -1 0`,
  Right: (r: number) => `0 -1 -${r}`,
  Left: (r: number) => `0 -1 ${r}`,
  Custom: (r: number, deg: number) => {
    deg = deg + 90;
    const x = Math.sin(deg / 57.3) * r;
    const z = Math.cos(deg / 57.3) * r;
    return `${-1 * x} -1 ${-1 * z}`;
  },
};

export const ArrowRotation = {
  Up: '-90 90 0',
  Down: '-90  -90 0',
  Right: '-90  0 0',
  Left: '-90  180 0',
  Custom: (deg: number) => {
    const normalizedRotation = 90;
    return `-90 ${normalizedRotation + deg} 0`;
  },
};

export type Navigator = {
  type: 'arrow' | 'hotspot';
  position: string;
  rotation: string;
  to: FileName;
};

export type Totem<T> = {
  position: string;
  rotation: string;
  content: T;
};

export type SceneConfig<T> = {
  cielo: string;
  rotacionCielo?: string;
  navegadores: Navigator[];
  totems: Totem<T>[];
};

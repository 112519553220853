import { AccessibleForwardTwoTone } from '@material-ui/icons';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find, whereEq } from 'ramda';
import { VacanciesAPI } from '@flfime/services/Vacancies/VacanciesAPI';
import { RootState } from '@flfime/store/store';
import { AsyncState } from '@flfime/types/OtherTypes';
import { Vacancy } from '@flfime/types/StandInformationTypes';

const namespace = 'vacancies';

export const getVacancies = createAsyncThunk(
  `${namespace}/getVacancies`,
  VacanciesAPI.getVacancies
);

export const vacanciesSlice = createSlice({
  name: namespace,
  initialState: {
    companiesVacancies: {} as { [companyId: string]: { vacancies: Vacancy[] } },
    vac_getState: {
      status: null as AsyncState,
      error: null as null | string,
    },
  },
  reducers: {
    addedCurriculumm: (
      state,
      {
        payload: { companyId, vacancyId },
      }: PayloadAction<{ vacancyId: string; companyId: string }>
    ) => {
      const { vacancies } = state.companiesVacancies[companyId];
      const vacancyToUpdate = vacancies.find((v) => v.vacancyId === vacancyId);
      if (!vacancyToUpdate) return;
      vacancyToUpdate.curriculumSent = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVacancies.pending, (state) => {
      state.vac_getState.status = 'loading';
    });
    builder.addCase(
      getVacancies.fulfilled,
      (state, { payload: { companyId, vacancies } }) => {
        state.companiesVacancies[companyId] = { vacancies };
        state.vac_getState.status = 'successful';
      }
    );
    builder.addCase(getVacancies.rejected, (state, { error }) => {
      state.vac_getState.status = 'rejected';
      state.vac_getState.error = String(error);
    });
  },
});

export const { addedCurriculumm } = vacanciesSlice.actions;

export const shouldGetVacancies = (companyId: string) => (state: RootState) =>
  !state.vacancies.companiesVacancies[companyId] &&
  state.vacancies.vac_getState.status != 'loading';

export const getVacanciesStatus = (state: RootState) =>
  state.vacancies.vac_getState;

export const vacanciesFrom = (companyId: string) => (state: RootState) =>
  state.vacancies.companiesVacancies[companyId]?.vacancies || [];

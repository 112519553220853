import { Button } from '@material-ui/core';
import { ModalContext } from '@flfime/components/Modal/ModalCtx';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { AuthenticationRepo } from '@flfime/services/Authentication/AuthenticationAPI';
import { currentUser } from '@flfime/store/CurrentUser/slice';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import { always } from 'ramda';
import { InformativeModal } from '@flfime/components/Modal/Modals/InformativeModal';

type SeePdfProps = {
  selectedPdf: string | null;
};
export const SeePdf = ({ selectedPdf }: SeePdfProps) => {
  const { openModalWith } = useContext(ModalContext);
  const _currentUser = useSelector(currentUser);
  const curriculum = _currentUser?.curriculum || '';
  const [downloadURL, setDownloadURL] = useState('');
  useEffect(() => {
    if (!curriculum) return;
    const showErrorModal = (e: Error) =>
      openModalWith(
        <InformativeModal
          message="Ocurrió un error al intentar obtener tu curriculum"
          type="error"
        />,
        'Normal'
      );
    const getUrl: T.Task<any> = pipe(
      TE.tryCatch(
        () => AuthenticationRepo.getUserCurriculumURL(curriculum),
        (r) => new Error(`${r}`)
      ),
      T.map(E.fold(showErrorModal, setDownloadURL))
    );
    getUrl();
  }, [curriculum, selectedPdf]);
  return (
    <Button
      disabled={!curriculum}
      variant="outlined"
      color="primary"
      component="label"
    >
      <a
        className="h-full w-full text-center"
        href={String(downloadURL)}
        target="_blank"
      >
        Ver pdf
      </a>
    </Button>
  );
};

import { compose, head, last, multiply, range, split, sum } from 'ramda';
import './momentLocales';
import moment from 'moment';
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@flfime/types/FirebaseTypes';
moment.locale('es');

export const momentES = moment;

export const pathContains = (s: string) => window.location.href.includes(s);

export const toBase64 = (file: File) =>
  new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => res(reader.result as string);
    reader.onerror = (error) => rej(error);
  });

export const fileListToArr = (filelist: FileList) => {
  const totalFiles = filelist.item.length;
  const fileKeys = range(0, totalFiles);
  const files = fileKeys.map((fk) => filelist[fk]);
  return files;
};

export const generateNotificationKey = () => `${new Date()}${Math.random()}`;

export const getChatId = ({
  userId,
  companyId,
}: {
  userId: string;
  companyId: string;
}) => `${companyId}${userId}`;

export const getSecondsFromStringifiedTimestamp = compose<
  string,
  string[],
  string,
  string[],
  string
>(head, split(','), last, split('(seconds='));

export const secondsToDate = (s: number) => new Date(s * 1000);

export const stringifiedTimestampToDate = compose<string, string, number, Date>(
  secondsToDate,
  Number,
  getSecondsFromStringifiedTimestamp
);

export const ssToData = ({
  idField,
  ss,
}: {
  ss: QuerySnapshot;
  idField: string;
}) => {
  return ss.docs.map((d) => ({
    [idField]: d.id,
    ...d.data(),
  }));
};

export const squared = (x: number) => x ** 2;

export const pythagoreanTheorem = (x: number, y: number) =>
  Math.sqrt(squared(x) + squared(y));

import React from 'react';
type ATextProps = {
  text: string;
  align: 'center' | 'left';
  position: string;
  scale: string;
  id?: string;
  color: string;
  rotation?: string
};

export const AText = ({
  align,
  text,
  position,
  id,
  scale,
  color,
  rotation
}: ATextProps) => {
  return (
    // @ts-ignore
    <a-text
      // font="/assets/aframe/custom-msdf.json"
      // font-image="/assets/aframe/custom.png"
      scale={scale}
      id={id}
      rotation={rotation}
      text={`value: ${text}; align: ${align}; color: ${color};`}
      position={position}
    >
      {/* @ts-ignore */}
    </a-text>
  );
};

import React, { createContext, useRef, useState } from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import { ReactNode } from 'react';
import { css } from '@emotion/react';

export const ModalContext = createContext({
  openModalWith: (c: ReactNode, props?: Omit<DialogProps, 'open'>) => {},
  closeModal: () => {},
  isOn: false,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);
  const close = () => setOpen(false);
  const [dialogProps, setDialogProps] = useState<Omit<DialogProps, 'open'>>({});
  const openModal = (n: ReactNode, props: Omit<DialogProps, 'open'> = {}) => {
    setContent(n);
    setDialogProps(props);
    setOpen(true);
  };
  return (
    <ModalContext.Provider
      value={{ closeModal: close, openModalWith: openModal, isOn: open }}
    >
      <Dialog {...dialogProps} open={open} onClose={close}>
        {content}
      </Dialog>
      {children}
    </ModalContext.Provider>
  );
};

import React from 'react';

type AVideoProps = {
  src: string;
};
export const AVideo = () => {
  return (
    // @ts-ignore
    <a-entity
      position="0 0 1"
      material="shader: chromakey; src: #chvideo; color: 0.1 0.9 0.2;"
      rotation="0 180 0"
      geometry="primitive: plane"
      // src="/assets/video/ch.mp4"
    >
      {/* @ts-ignore */}
    </a-entity>
  );
};

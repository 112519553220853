import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDDO-48JsrJBw-oxG9PA7OO1-d8s4Ddq_0',
  authDomain: 'recorridosvirtualesfime.firebaseapp.com',
  projectId: 'recorridosvirtualesfime',
  storageBucket: 'recorridosvirtualesfime.appspot.com',
  messagingSenderId: '637961065865',
  appId: '1:637961065865:web:fbd9401abc9a08469fbd58',
  measurementId: 'G-CQREG2H30Q',
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();
const storage = app.storage();
const functions = app.functions();
const analytics = app.analytics();

const collections = {
  chatsCollection: db.collection('chats'),
  messagesCollection: (chatId: string) =>
    collections.chatsCollection.doc(chatId).collection('messages'),
  usersCollection: db.collection('visitantes'),
  vacanciesCollection: (clientId: string) =>
    db.collection('clientes').doc(clientId).collection('vacantes'),
  postulationsCollection: (clientId: string, vacancyId: string) =>
    collections
      .vacanciesCollection(clientId)
      .doc(vacancyId)
      .collection('postulaciones'),
};

const folders = {
  curriculumsRef: (fileName: string) =>
    storage.ref(`ferialaboralfime/clientes/curriculums/${fileName}`),
};

export const {
  chatsCollection,
  messagesCollection,
  usersCollection,
  vacanciesCollection,
  postulationsCollection,
} = collections;

export const addVisita = async (): Promise<void> => {
  await functions.httpsCallable('addVisita')();
  return;
};

export const addVisitaCliente = async (clientid: string): Promise<void> => {
  await functions.httpsCallable('addVisitaCliente')({ id: clientid });
  return;
};

export const { curriculumsRef } = folders;

export { auth, db, storage, functions, analytics };

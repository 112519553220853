import React, { ReactNode } from 'react';

type AAssetsProps = {
  children: ReactNode[] | ReactNode;
};

export const AAssets = ({ children }: AAssetsProps) => {
  // @ts-ignore
  return <a-assets>{children}</a-assets>;
};

import styled from '@emotion/styled';
import React from 'react';

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.devices.mobileSm} {
    font-size: 0.8em;
  }
  ${({ theme }) => theme.devices.mobileMd} {
    font-size: 1em;
  }
`;

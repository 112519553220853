import React from 'react';
import { Carousel } from '@flfime/components/Carousel/Carousel';

type VideosModalProps = {
  videos: string[];
};
export const VideosModal = ({ videos }: VideosModalProps) => {
  return (
    <div className="mx-auto flex items-center w-full  md:w-2/3 xl:w-1/2">
      <Carousel videos={videos} />
    </div>
  );
};

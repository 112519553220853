import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isTransitioning } from '@oop/data';

type ASkyProps = {
  color?: string;
  src?: string;
  rotation?: string;
  id?: string;
};

export const ASky = ({ src, rotation, color }: ASkyProps) => {
  const _isTransitioning = useSelector(isTransitioning);
  const from = _isTransitioning ? 'white' : 'black';
  const to = _isTransitioning ? 'black' : 'white';
  return (
    // @ts-ignore
    <a-sky
      id="sky"
      src={src}
      // src="#render0001"
      rotation={rotation}
      animation={`property: components.material.material.color; type: color; to: ${to} ; from: ${from}; loop: false; dur: 300`}
      // animation__fade="property: components.material.material.color; type: color; from: #FFF; to: #000; dur: 300; startEvents: fade"
      // animation__fadeback="property: components.material.material.color; type: color; from: #000; to: #FFF; dur: 300; startEvents: animationcomplete__fade"
    >
      {/* @ts-ignore */}
    </a-sky>
  );
};

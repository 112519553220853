import React from 'react';
import { useState } from 'react';

type BurgerBtnProps = {
  onClick: () => any;
  isOpen: boolean;
};
export const BurgerBtn = ({ onClick, isOpen: open }: BurgerBtnProps) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <button
      onClick={handleClick}
      className="relative h-10 w-10 flex items-center justify-center outline-none focus:outline-none"
    >
      <div
        className={`transition-all duration-300 ml-1 w-5 h-1 rounded-md absolute top-0 left-0 bg-white transform origin-top-left ${
          open ? 'rotate-45' : ''
        }`}
      ></div>
      <div
        className={`transition-all duration-300 w-4 px-[23.5px] h-1 rounded-md bg-white transform origin-center ${
          open ? '-rotate-45' : ''
        }`}
      ></div>
      <div
        className={`transition-all duration-300 mr-1 w-5 h-1 rounded-md absolute bottom-0 right-0 bg-white ml-auto transform origin-bottom-right ${
          open ? 'rotate-45' : ''
        }`}
      ></div>
    </button>
  );
};

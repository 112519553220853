import React from 'react';
import { Title } from '@flfime/components/Text/Title';
import { currentUser } from '@flfime/store/CurrentUser/slice';
import { useDispatch, useSelector } from 'react-redux';
import { PulseSpinner } from '@flfime/components/Spinners/PulseSpinner/PulseSpinner';
import { Button, useMediaQuery } from '@material-ui/core';
import { useContext, useState } from 'react';
import { AsyncState } from '@flfime/types/OtherTypes';
import { Colors } from '@flfime/theme/Colors';
import { useDropzone } from 'react-dropzone';
import { always, and, cond, not } from 'ramda';
import { fileListToArr, toBase64 } from '@flfime/helpers/Misc';
import { ModalContext } from '@flfime/components/Modal/ModalCtx';
import { InformativeModal } from '@flfime/components/Modal/Modals/InformativeModal';
import { SelectPdf } from '@flfime/pages/Home/components/UploadCurriculum/components/SelectPdf';
import { SeePdf } from '@flfime/pages/Home/components/UploadCurriculum/components/SeePdf';

export const UploadCurriculum = () => {
  const _currentUser = useSelector(currentUser);
  const [pdf, setPdf] = useState<string | null>(null);
  const { openModalWith } = useContext(ModalContext);
  const openPdf = (pdf: string) =>
    openModalWith(
      <iframe className="w-[90vw] md:w-[60vw] h-[80vh]" src={pdf}>
        Archivo no disponible para revisar
      </iframe>,
      'Normal'
    );
  return (
    <div>
      {_currentUser ? (
        <div>
          <Title type="h2">
            {_currentUser?.curriculum
              ? 'Ya has subido tu curriculum, si lo deseas puedes cambiarlo.'
              : 'Parece que aún no has subido tu curriculum, aquí puedes hacerlo.'}
          </Title>
          <div className="flex flex-col justify-between mt-2">
            <SeePdf selectedPdf={pdf} />
            <div className="py-2"></div>
            <SelectPdf
              setSelectedPdf={setPdf}
              openPdf={openPdf}
              selectedPdf={pdf}
            />
          </div>
        </div>
      ) : (
        <PulseSpinner />
      )}
    </div>
  );
};

import { ArrowDownward } from '@material-ui/icons';
import { StandInformations } from '@flfime/config/StandInformation';
import {
  ArrowPosition,
  ArrowRotation,
  SceneConfig as SceneConfigT,
  SkyRotationNormalization,
} from '@oop/data';
import { Renders, RFNs } from './SceneConfigTypes';
import { StandInformation } from '@flfime/types/StandInformationTypes';
import { has } from 'ramda';
import { Totem } from '@flfime/types/SceneConfigTypes';
export type SceneConfig = SceneConfigT<StandInformation>;

const generateRotation = (phi: number) => {
  return `0 ${(phi - Math.PI) * 57.3} 0`;
};

const buildConfig = <T extends Record<Renders, SceneConfig>>(t: T) => {
  return t;
};

const TotemPositions = {
  Left: {
    position: '0.8 1 1.6',
    rotation: '0 -160 0',
  },
  Up: {
    rotation: '0 120 0',
    position: '-1.8 1 0.8',
  },
  Right: {
    rotation: '0 -60 0',
    position: '1.7 1 -1.7',
  },
};

export const SceneConfigs = {
  [RFNs.r0]: {
    cielo: `${RFNs.r0}.jpg`,
    navegadores: [
      {
        to: RFNs.r1,
        rotation: ArrowRotation.Up,
        position: ArrowPosition.Up(5),
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r1]: {
    cielo: `${RFNs.r1}.jpg`,
    navegadores: [
      
      {
        to: RFNs.r0,
        rotation: '-90 -86 0',
        position: '5.9 -1 0',
        type: 'arrow',
      },
     
      {
        to: RFNs.r8,
        rotation: '-90 54 0',
        position: '-5.6 -0.3 -1.1',
        type: 'arrow',
      },
      {
        to: RFNs.r3,
        rotation: '-90 128 0',
        position: '-5.6 -0.3 2.6',
        type: 'arrow',
      },
    ],
    totems: [],
  },
  [RFNs.r2]: {
    cielo: `${RFNs.r2}.jpg`,
    navegadores: [
      {
        to: RFNs.r0,
        rotation: '-90 -62 0 ',
        position: '6 -1.3 0',
        type: 'hotspot',
      },
      {
        to: RFNs.r3,
        rotation: '-90 110 0',
        position: '0 -1.7 6.4',
        type: 'hotspot',
      },
      {
        to: RFNs.r8,
        rotation: '-90 110 0',
        position: '-1.4 -1.7 -4.5',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AMI Automation'],
        position: '-1.7 0.9 1',
        rotation: '0 117 0',
      },
    ],
  },
  [RFNs.r3]: {
    cielo: `${RFNs.r3}.jpg`,
    rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
    navegadores: [
      {
        to: RFNs.r8,
        rotation: '-90 -62 0 ',
        position: '-5.8 -0.8 -2.7',
        type: 'hotspot',
      },
      {
        to: RFNs.r4,
        rotation: '-90 -62 0 ',
        position: '5.6 -1.6 2.5',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['ABB Mexico'],
        position: '1.2 0.8 2.8',
        rotation: '0 -156 0',
      },
      {
        content: StandInformations['AMI Automation'],
        position: '-3 0.8 0.4',
        rotation: '0 112 0',
      },
    ],
  },
  
  [RFNs.r4]: {
    cielo: `${RFNs.r4}.jpg`,
    rotacionCielo: SkyRotationNormalization.southToNorth,
    navegadores: [
      {
        to: RFNs.r5,
        rotation: '-90 -155 0',
        position: '-0.2 -1.8 6.3',
        type: 'hotspot',
      },
      {
        to: RFNs.r3,
        rotation: '-90 25 0',
        position: '-6 -1.2 -2.1',
        type: 'hotspot',
      },
     
    ],
    totems: [
      {
        content: StandInformations['Blue Yonder'],
        position: '1.9 0.8 2.4',
        rotation: '0 -137 0',
      },
      {
        content: StandInformations['Banorte'],
        position: '2.2 0.8 -0.2',
        rotation: '0 -95 0',
      },
    ],
  },
  
  [RFNs.r5]: {
    cielo: `${RFNs.r5}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
      {
        to: RFNs.r4,
        rotation: '-90 120 0',
        position: '4.2 -1 -4.7 ',
        type: 'hotspot',
      },

      {
        to: RFNs.r6,
        rotation: '-90 -61 0',
        position: '-3.9 -1 -3.6',
        type: 'hotspot',
      },
    ],
    totems: [
     
      {
        content: StandInformations['INTERNACIONALIZACIÓN'],
        position: '-0.8 1 1.8',
        rotation: '0 165 0',
      },
      {
        content: StandInformations['Banorte'],
        position: '-2.4  1 -0',
        rotation: '0 104 0',
      },
      {
        content: StandInformations['Hogar Inteligente'],
        position: '1.4 1 1.7',
        rotation: '0 -145 0',
      },
      
    ],
  },
  [RFNs.r6]: {
    cielo: `${RFNs.r6}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
    
      {
        to: RFNs.r11,
        rotation: '-90 -61 0',
        position: '6.8 -1.2 0.3',
        type: 'hotspot',
      },
      
      {
        to: RFNs.r5,
        rotation: '-90 -61 0',
        position: '-5.7 -1.7 0.2',
        type: 'hotspot',
      },
      
    ],
    totems: [
      {
        content: StandInformations['BANCO BASE, S.A.,'],
        position: '-0.4 0.9 1.7',
        rotation: '0 165 0',
      },
      {
        content: StandInformations['KIA México'],
        position: '0.2 0.9 1.7',
        rotation: '0 -168 0',
      },
      {
        content: StandInformations['American Axle & Manufacturing'],
        position: '-1.1 1.1 -1.7',
        rotation: '0 31 0',
      },
    ],
  },
  [RFNs.r7]: {
    cielo: `${RFNs.r7}.jpg`,

    navegadores: [
      {
        to: RFNs.r8,
        rotation: '-90 31 0',
        position: '-2.6 -1 -5.1',
        type: 'arrow',
      },
      {
        to: RFNs.r6,
        rotation: '-90 -152 0',
        position: '3.2 -1 5.9',
        type: 'arrow',
      },
    ],
    totems: [
      {
        content: StandInformations['Supra Desarrollos Tecnológicos S.A de C.V'],
        position: '-2.5 0.7 0.9',
        rotation: '0 110 0',
      },
    ],
  },
  [RFNs.r8]: {
    cielo: `${RFNs.r8}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r1,
        rotation: '-90 110 0',
        position: '4.8 -1 4.3',
        type: 'hotspot',
      },
      {
        to: RFNs.r9,
        rotation: '-90 110 0',
        position: '-3.6 -1 -2.9',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['212 Executive Search & Consulting'],
        position: '-0.9 0.8 -1.9',
        rotation: '0 27 0',
      },
    ],
  },
  [RFNs.r9]: {
    cielo: `${RFNs.r9}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r10,
        rotation: '-90 110 0',
        position: '-3.8 -1 -1.8',
        type: 'hotspot',
      },
      {
        to: RFNs.r8,
        rotation: '-90 110 0',
        position: '3.7 -1 1.1',
        type: 'hotspot',
      },
      
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-0.9 0.8 -1.9',
        rotation: '0 27 0',
      },
    ],
  },
  [RFNs.r10]: {
    cielo: `${RFNs.r10}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,
    navegadores: [
      {
        to: RFNs.r11,
        rotation: '-90 110 0',
        position: '-4.2 -1 4',
        type: 'hotspot',
      },
      {
        to: RFNs.r6,
        rotation: '-90 110 0',
        position: '-2.5 -1 8.7',
        type: 'hotspot',
      },
      {
        to: RFNs.r9,
        rotation: '-90 110 0',
        position: '3.5 -1 1.3',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-1.5 0.7 -1.8',
        rotation: '0 27 0',
      },
    ],
  },
  [RFNs.r11]: {
    cielo: `${RFNs.r11}.jpg`,
    rotacionCielo: SkyRotationNormalization.eastToNorth,
    navegadores: [
      {
        to: RFNs.r10,
        rotation: '-90 110 0',
        position: '-1.8 -1 -3.5',
        type: 'hotspot',
      },
      {
        to: RFNs.r6,
        rotation: '-90 110 0',
        position: '3.6 -1 4.1',
        type: 'hotspot',
      },
      {
        to: RFNs.r12,
        rotation: '-90 110 0',
        position: '-3.5 -1 3.2',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['212 Executive Search & Consulting'],
        position: '-1 0.9 2.1',
        rotation: '0 152 0',
      },
    ],
  },
  [RFNs.r12]: {
    cielo: `${RFNs.r12}.jpg`,

    navegadores: [
      {
        to: RFNs.r13,
        rotation: '-90 110 0',
        position: '-3.4 -1.4 -4',
        type: 'hotspot',
      },
      {
        to: RFNs.r11,
        rotation: '-90 110 0',
        position: '2.3 -1.4 3.6',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['ATOS'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r13]: {
    cielo: `${RFNs.r13}.jpg`,

    navegadores: [
      {
        to: RFNs.r14,
        rotation: '-90 110 0',
        position: '-3.5 -1.4 -4.7',
        type: 'hotspot',
      },
      {
        to: RFNs.r12,
        rotation: '-90 110 0',
        position: '1.9 -1.4 4.2',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-1.9 1 1.1',
        rotation: '0 118 0',
      },
    ],
  },

  [RFNs.r14]: {
    cielo: `${RFNs.r14}.jpg`,

    navegadores: [
      {
        to: RFNs.r15,
        rotation: '-90 110 0',
        position: '-3.2 -1.4 -3.8',
        type: 'hotspot',
      },
      {
        to: RFNs.r13,
        rotation: '-90 110 0',
        position: '2.1 -1.4 4',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['ABB Mexico'],
        position: '-1.9 1 1',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r15]: {
    cielo: `${RFNs.r15}.jpg`,

    navegadores: [
      {
        to: RFNs.r16,
        rotation: '-90 110 0',
        position: '-3.3 -1.4 -4.3',
        type: 'hotspot',
      },
      {
        to: RFNs.r14,
        rotation: '-90 110 0',
        position: '2.2 -1.4 4.3',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['Sistemas Automotrices de México, S.A. de C.V.'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r16]: {
    cielo: `${RFNs.r16}.jpg`,

    navegadores: [
      {
        to: RFNs.r17,
        rotation: '-90 110 0',
        position: '-3.2 -1.4 -3.5',
        type: 'hotspot',
      },
      {
        to: RFNs.r15,
        rotation: '-90 110 0',
        position: '2.3 -1.4 4 ',
        type: 'hotspot',
      },
      {
        to: RFNs.r23,
        rotation: '-90 110 0',
        position: '2.1 -1.4  -2.3 ',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AFL Telecomunicaciones'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r17]: {
    cielo: `${RFNs.r17}.jpg`,

    navegadores: [
      {
        to: RFNs.r18,
        rotation: '-90 110 0',
        position: '-2.8 -1.4 -3.6',
        type: 'hotspot',
      },
      {
        to: RFNs.r16,
        rotation: '-90 110 0',
        position: '2.9 -1.4 3.8',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['ABB Mexico'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
 
  [RFNs.r18]: {
    cielo: `${RFNs.r18}.jpg`,

    navegadores: [
      {
        to: RFNs.r19,
        rotation: '-90 110 0',
        position: '-2.8 -1.4 -3.6',
        type: 'hotspot',
      },
      {
        to: RFNs.r17,
        rotation: '-90 110 0',
        position: '2.9 -1.4 3.8',
        type: 'hotspot',
      },
     
    ],
    totems: [
      {
        content: StandInformations['ABB Mexico'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r19]: {
    cielo: `${RFNs.r19}.jpg`,

    navegadores: [
      {
        to: RFNs.r20,
        rotation: '-90 110 0',
        position: '-2.8 -1.4 -3.6',
        type: 'hotspot',
      },
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '3.7 -1.4 3.1',
        type: 'hotspot',
      },
      {
        to: RFNs.r30,
        rotation: '-90 110 0',
        position: '4.5 -1.4 -4.7',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['BANCO BASE, S.A.,'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r20]: {
    cielo: `${RFNs.r20}.jpg`,

    navegadores: [
      {
        to: RFNs.r21,
        rotation: '-90 110 0',
        position: '-2.8 -1.4 -3.6',
        type: 'hotspot',
      },
      {
        to: RFNs.r19,
        rotation: '-90 110 0',
        position: '2.9 -1.4 3.8',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r21]: {
    cielo: `${RFNs.r21}.jpg`,

    navegadores: [
      {
        to: RFNs.r22,
        rotation: '-90 110 0',
        position: '-2.8 -1.4 -3.6',
        type: 'hotspot',
      },
      {
        to: RFNs.r20,
        rotation: '-90 110 0',
        position: '2.9 -1.4 3.8',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-1.9 1 1.1',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r22]: {
    cielo: `${RFNs.r22}.jpg`,

    navegadores: [
      {
        to: RFNs.r21,
        rotation: '-90 110 0',
        position: '2.9 -1.4 3.8',
        type: 'hotspot',
      },
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '0.3 -1.4 -2.4',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-1.9 1 0.7',
        rotation: '0 118 0',
      },
    ],
  },
  [RFNs.r23]: {
    cielo: `${RFNs.r23}.jpg`,
    rotacionCielo: SkyRotationNormalization.westToNorth,

    navegadores: [
      {
        to: RFNs.r24,
        rotation: '-90 110 0',
        position: '-4.6 -2.2 -3.8',
        type: 'hotspot',
      },
      {
        to: RFNs.r16,
        rotation: '-90 110 0',
        position: '-3.7 -2.2 3.6',
        type: 'hotspot',
      },
    ],
    totems: [],
  },
  [RFNs.r24]: {
    cielo: `${RFNs.r24}.jpg`,

    navegadores: [
      {
        to: RFNs.r17,
        rotation: '-90 110 0',
        position: '3.7 -2.2 2.5 ',
        type: 'hotspot',
      },
      {
        to: RFNs.r23,
        rotation: '-90 110 0',
        position: '3.4 -1.7 -5.1',
        type: 'hotspot',
      },
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '-6.2 -2.1 8.9',
        type: 'hotspot',
      },
      
    ],
    totems: [],
  },
  [RFNs.r25]: {
    cielo: `${RFNs.r25}.jpg`,

    navegadores: [
      {
        to: RFNs.r43,
        rotation: '-90 110 0',
        position: '-5.3 -2.2 -3.2',
        type: 'hotspot',
      },
      {
        to: RFNs.r42,
        rotation: '-90 110 0',
        position: '2.6 -2.2 -3.2',
        type: 'hotspot',
      },
      {
        to: RFNs.r35,
        rotation: '-90 110 0',
        position: '-3.3 -2.2 4.4',
        type: 'hotspot',
      },
      {
        to: RFNs.r34,
        rotation: '-90 110 0',
        position: '4.7 -2.2 4.8',
        type: 'hotspot',
      },
      {
        to: RFNs.r26,
        rotation: '-90 110 0',
        position: '-8.6 -2.2 0.4',
        type: 'hotspot',
      },
      {
        to: RFNs.r24,
        rotation: '-90 110 0',
        position: '7.1 -2 0',
        type: 'hotspot',
      },
    ],
    totems: [],
  },

  [RFNs.r26]: {
    cielo: `${RFNs.r26}.jpg`,

    navegadores: [
      {
        to: RFNs.r44,
        rotation: '-90 110 0',
        position: '0.7 -2.2 -3.2',
        type: 'hotspot',
      },
      {
        to: RFNs.r45,
        rotation: '-90 110 0',
        position: '-5.8 -2.2 -3.2',
        type: 'hotspot',
      },

      {
        to: RFNs.r37,
        rotation: '-90 110 0',
        position: '-1.9 -2.2 4.5',
        type: 'hotspot',
      },
      {
        to: RFNs.r36,
        rotation: '-90 110 0',
        position: '5.2 -2.2 4.5',
        type: 'hotspot',
      },
      {
        to: RFNs.r27,
        rotation: '-90 110 0',
        position: '-7.8 -1.9 0.4',
        type: 'hotspot',
      },
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '7.1 -2 0',
        type: 'hotspot',
      },
    ],
    totems: [],
  },
  [RFNs.r27]: {
    cielo: `${RFNs.r27}.jpg`,

    navegadores: [
      {
        to: RFNs.r46,
        rotation: '-90 110 0',
        position: '-3.6 -2.2 -3.3',
        type: 'hotspot',
      },
      {
        to: RFNs.r39,
        rotation: '-90 110 0',
        position: '-4.1 -2.2 4.3',
        type: 'hotspot',
      },
      {
        to: RFNs.r38,
        rotation: '-90 110 0',
        position: '3.3 -2.2 4.5',
        type: 'hotspot',
      },
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '-7.8 -1.9 0.4',
        type: 'hotspot',
      },
      {
        to: RFNs.r26,
        rotation: '-90 110 0',
        position: '7.1 -2 0',
        type: 'hotspot',
      },
    ],
    totems: [],
  },

  [RFNs.r28]: {
    cielo: `${RFNs.r28}.jpg`,

    navegadores: [
      {
        to: RFNs.r47,
        rotation: '-90 110 0',
        position: '4.6 -2.2 -4',
        type: 'hotspot',
      },
      {
        to: RFNs.r48,
        rotation: '-90 110 0',
        position: '-3.1 -2.2 -4',
        type: 'hotspot',
      },
      {
        to: RFNs.r41,
        rotation: '-90 110 0',
        position: '-2.9 -2.2 5',
        type: 'hotspot',
      },
      {
        to: RFNs.r40,
        rotation: '-90 110 0',
        position: '4.7 -2.2 5',
        type: 'hotspot',
      },
      {
        to: RFNs.r29,
        rotation: '-90 110 0',
        position: '-5 -2.3 0',
        type: 'hotspot',
      },

      {
        to: RFNs.r27,
        rotation: '-90 110 0',
        position: '7.1 -2 0',
        type: 'hotspot',
      },
    ],
    totems: [],
  },
  [RFNs.r29]: {
    cielo: `${RFNs.r29}.jpg`,

    navegadores: [
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '4.2 -1.4 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r30]: {
    cielo: `${RFNs.r30}.jpg`,

    navegadores: [
      {
        to: RFNs.r23,
        rotation: '-90 110 0',
        position: '3 -1 -3.4',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['Risoul'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r31]: {
    cielo: `${RFNs.r31}.jpg`,

    navegadores: [
      {
        to: RFNs.r24,
        rotation: '-90 110 0',
        position: '3 -1 -1.1',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['Risoul'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r34]: {
    cielo: `${RFNs.r34}.jpg`,

    navegadores: [
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '3 -1 -0.7',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AgileThought'],
        position: '-2.1 0.7 1.1',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r35]: {
    cielo: `${RFNs.r35}.jpg`,

    navegadores: [
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['American Axle & Manufacturing'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r36]: {
    cielo: `${RFNs.r36}.jpg`,

    navegadores: [
      {
        to: RFNs.r26,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['FASTENAL'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r37]: {
    cielo: `${RFNs.r37}.jpg`,

    navegadores: [
      {
        to: RFNs.r26,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['Cia Talentos'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r38]: {
    cielo: `${RFNs.r38}.jpg`,

    navegadores: [
      {
        to: RFNs.r27,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['INTERNACIONALIZACIÓN FIME'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r39]: {
    cielo: `${RFNs.r39}.jpg`,

    navegadores: [
      {
        to: RFNs.r27,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AMI Automation'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r40]: {
    cielo: `${RFNs.r40}.jpg`,

    navegadores: [
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AMI Automation'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r41]: {
    cielo: `${RFNs.r41}.jpg`,

    navegadores: [
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['Banorte'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r32]: {
    cielo: `${RFNs.r32}.jpg`,

    navegadores: [
      {
        to: RFNs.r23,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AMI Automation'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r33]: {
    cielo: `${RFNs.r33}.jpg`,

    navegadores: [
      {
        to: RFNs.r24,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AFL Telecomunicaciones'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r42]: {
    cielo: `${RFNs.r42}.jpg`,

    navegadores: [
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['American Industries'],
        position: '-1.8 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r43]: {
    cielo: `${RFNs.r43}.jpg`,

    navegadores: [
      {
        to: RFNs.r25,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['212 Executive Search & Consulting'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r44]: {
    cielo: `${RFNs.r44}.jpg`,

    navegadores: [
      {
        to: RFNs.r26,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['Banorte'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r45]: {
    cielo: `${RFNs.r45}.jpg`,

    navegadores: [
      {
        to: RFNs.r26,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['ATOS'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r46]: {
    cielo: `${RFNs.r46}.jpg`,

    navegadores: [
      {
        to: RFNs.r27,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AFL Telecomunicaciones'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r47]: {
    cielo: `${RFNs.r47}.jpg`,

    navegadores: [
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['212 Executive Search & Consulting'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
  [RFNs.r48]: {
    cielo: `${RFNs.r48}.jpg`,

    navegadores: [
      {
        to: RFNs.r28,
        rotation: '-90 110 0',
        position: '3 -1 0',
        type: 'hotspot',
      },
    ],
    totems: [
      {
        content: StandInformations['AFL Telecomunicaciones'],
        position: '-2.1 0.7 1.2',
        rotation: '0 116 0',
      },
    ],
  },
   

  /**
   * CORTE
   */

  // [RFNs.r0]: {
  //   cielo: `${RFNs.r0}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r1,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(5),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r1]: {
  //   cielo: `${RFNs.r1}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r0,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(5),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r2,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r2]: {
  //   cielo: `${RFNs.r2}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r10,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(5),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r1,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(5),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r3,
  //       rotation: ArrowRotation.Custom(60),
  //       position: ArrowPosition.Custom(5, 60),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r9,
  //       rotation: ArrowRotation.Custom(-45),
  //       position: ArrowPosition.Custom(5, -45),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r3]: {
  //   cielo: `${RFNs.r3}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r2,
  //       rotation: ArrowRotation.Custom(-100),
  //       position: ArrowPosition.Custom(3, -120),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r4,
  //       rotation: ArrowRotation.Custom(110),
  //       position: ArrowPosition.Custom(6, 100),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r5,
  //       rotation: ArrowRotation.Custom(70),
  //       position: ArrowPosition.Custom(6, 60),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r6,
  //       rotation: ArrowRotation.Custom(45),
  //       position: ArrowPosition.Custom(8, 35),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r7,
  //       rotation: ArrowRotation.Custom(10),
  //       position: ArrowPosition.Custom(6.5, 10),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r8,
  //       rotation: ArrowRotation.Custom(10),
  //       position: ArrowPosition.Custom(6, -30),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r4]: {
  //   cielo: `${RFNs.r4}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.eastToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r3,
  //       rotation: ArrowRotation.Custom(-10),
  //       position: ArrowPosition.Custom(5, -45),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Left,
  //       content: StandInformations['Gruma'],
  //     },
  //   ],
  // },
  // [RFNs.r5]: {
  //   cielo: `${RFNs.r5}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.eastToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r3,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(5, -110),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Left,
  //       content: StandInformations['Gruma'],
  //     },
  //   ],
  // },
  // [RFNs.r6]: {
  //   cielo: `${RFNs.r6}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.eastToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r3,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(6, -140),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Left,
  //       position: '1.2  1 2.5',
  //       content: StandInformations['Raad_Ingenieros'],
  //     },
  //   ],
  // },
  // [RFNs.r7]: {
  //   cielo: `${RFNs.r7}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r3,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(5, -180),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       rotation: '0 120 0',
  //       position: '-1.8 1 0.8',
  //       content: StandInformations['FIME'],
  //     },
  //   ],
  // },
  // [RFNs.r8]: {
  //   cielo: `${RFNs.r8}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r3,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(5, 130),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Up,
  //       content: StandInformations['Caterpillar'],
  //     },
  //   ],
  // },
  // [RFNs.r9]: {
  //   cielo: `${RFNs.r9}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r2,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(5, 140),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Right,
  //       content: StandInformations['Air_Liquide'],
  //     },
  //   ],
  // },
  // [RFNs.r10]: {
  //   cielo: `${RFNs.r10}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r11,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(3, -45),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r12,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r2,
  //       rotation: ArrowRotation.Custom(-210),
  //       position: ArrowPosition.Custom(5, -210),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r11]: {
  //   cielo: `${RFNs.r11}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r10,
  //       rotation: ArrowRotation.Custom(40),
  //       position: ArrowPosition.Custom(2, -190),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Right,
  //       content: StandInformations['Lego'],
  //     },
  //   ],
  // },
  // [RFNs.r12]: {
  //   cielo: `${RFNs.r12}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r13,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r10,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Castelec_Internacional'],
  //     },
  //   ],
  // },
  // [RFNs.r13]: {
  //   cielo: `${RFNs.r13}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r12,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r14,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Custom(3, 45),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r14]: {
  //   cielo: `${RFNs.r14}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r13,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Custom(3, 210),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r15,
  //       rotation: ArrowRotation.Up,

  //       position: ArrowPosition.Up(3),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Gruma'],
  //     },
  //   ],
  // },
  // [RFNs.r15]: {
  //   cielo: `${RFNs.r15}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r14,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(3),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r17,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(2),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r16,
  //       rotation: ArrowRotation.Custom(-45),
  //       position: ArrowPosition.Custom(3, -45),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r16]: {
  //   cielo: `${RFNs.r16}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedRightStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r17,
  //       rotation: ArrowRotation.Custom(135),
  //       position: ArrowPosition.Custom(3, 135),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r18,
  //       rotation: ArrowRotation.Custom(45),
  //       position: ArrowPosition.Custom(3, 45),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r17]: {
  //   cielo: `${RFNs.r17}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r15,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(2),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r18,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r16,
  //       rotation: ArrowRotation.Custom(-60),
  //       position: ArrowPosition.Custom(3, -60),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Frisa'],
  //     },
  //   ],
  // },
  // [RFNs.r18]: {
  //   cielo: `${RFNs.r18}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r17,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r16,
  //       rotation: ArrowRotation.Custom(-135),
  //       position: ArrowPosition.Custom(3, -135),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r19,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Custom(3, 40),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r20,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r19]: {
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   cielo: `${RFNs.r19}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r18,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(2),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Open_Service'],
  //     },
  //   ],
  // },
  // [RFNs.r20]: {
  //   cielo: `${RFNs.r20}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r18,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r21,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Custom(3, 40),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r22,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r21]: {
  //   cielo: `${RFNs.r21}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r20,
  //       rotation: ArrowRotation.Custom(190),
  //       position: ArrowPosition.Custom(2, 190),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r22,
  //       rotation: ArrowRotation.Custom(-10),
  //       position: ArrowPosition.Custom(2, -10),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Fastenal'],
  //     },
  //   ],
  // },
  // [RFNs.r22]: {
  //   cielo: `${RFNs.r22}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r20,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r23,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Custom(3, 40),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r24,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r45,
  //       rotation: ArrowRotation.Right,
  //       position: ArrowPosition.Right(3),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r23]: {
  //   cielo: `${RFNs.r23}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r22,
  //       rotation: ArrowRotation.Custom(190),
  //       position: ArrowPosition.Custom(2, 190),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r24,
  //       rotation: ArrowRotation.Custom(-10),
  //       position: ArrowPosition.Custom(2, -10),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Diram'],
  //     },
  //   ],
  // },
  // [RFNs.r24]: {
  //   cielo: `${RFNs.r24}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r22,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r25,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Custom(3, 40),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r26,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r25]: {
  //   cielo: `${RFNs.r25}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r24,
  //       rotation: ArrowRotation.Custom(190),
  //       position: ArrowPosition.Custom(2, 190),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r26,
  //       rotation: ArrowRotation.Custom(-10),
  //       position: ArrowPosition.Custom(2.5, -20),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r27,
  //       rotation: ArrowRotation.Custom(10),
  //       position: ArrowPosition.Custom(2.5, 20),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Daltile_México'],
  //     },
  //   ],
  // },
  // [RFNs.r26]: {
  //   cielo: `${RFNs.r26}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r25,
  //       rotation: ArrowRotation.Custom(170),
  //       position: ArrowPosition.Custom(2, 170),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r27,
  //       rotation: ArrowRotation.Custom(20),
  //       position: ArrowPosition.Custom(3, 20),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Right,
  //       content: StandInformations['212_EXECUTIVE_SEARCH_&_CONSULTING'],
  //     },
  //   ],
  // },
  // [RFNs.r27]: {
  //   cielo: `${RFNs.r27}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r25,
  //       rotation: ArrowRotation.Custom(170),
  //       position: ArrowPosition.Custom(2, 170),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r26,
  //       rotation: ArrowRotation.Custom(-170),
  //       position: ArrowPosition.Custom(3, -150),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r28,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Creatoris_informatica'],
  //     },
  //   ],
  // },
  // [RFNs.r28]: {
  //   cielo: `${RFNs.r28}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r27,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r29,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r29]: {
  //   cielo: `${RFNs.r29}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r30,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r27,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r32,
  //       rotation: ArrowRotation.Right,
  //       position: ArrowPosition.Right(4),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Agile_Thought'],
  //     },
  //   ],
  // },
  // [RFNs.r30]: {
  //   cielo: `${RFNs.r30}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r29,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r31,
  //       rotation: ArrowRotation.Custom(20),
  //       position: ArrowPosition.Custom(3, 20),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r32,
  //       rotation: ArrowRotation.Custom(-135),
  //       position: ArrowPosition.Custom(4, -135),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r31]: {
  //   cielo: `${RFNs.r31}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedLeftStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r30,
  //       rotation: ArrowRotation.Custom(170),
  //       position: ArrowPosition.Custom(2, 170),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-0 1 2.2',
  //       rotation: '0 200 0',
  //       content: StandInformations['Banorte'],
  //     },
  //   ],
  // },
  // [RFNs.r32]: {
  //   cielo: `${RFNs.r32}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r30,
  //       rotation: ArrowRotation.Custom(60),
  //       position: ArrowPosition.Custom(4, 60),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r34,
  //       rotation: ArrowRotation.Custom(60),
  //       position: ArrowPosition.Custom(2, -100),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r29,
  //       rotation: ArrowRotation.Left,
  //       position: ArrowPosition.Left(4),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r33,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Up(2),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r35,
  //       rotation: ArrowRotation.Right,
  //       position: ArrowPosition.Right(4),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [],
  // },
  // [RFNs.r33]: {
  //   cielo: `${RFNs.r33}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r32,
  //       rotation: ArrowRotation.Down,
  //       position: ArrowPosition.Down(2),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Up,
  //       position: '-1.5 1 1.8',
  //       content: StandInformations['Schlumberger'],
  //     },
  //   ],
  // },
  // [RFNs.r34]: {
  //   cielo: `${RFNs.r34}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.southToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r32,
  //       rotation: ArrowRotation.Custom(60),
  //       position: ArrowPosition.Custom(2, 80),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r35,
  //       rotation: ArrowRotation.Up,
  //       position: ArrowPosition.Right(3),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '1.8 1 -1.3',
  //       rotation: '0 -60 0',
  //       content: StandInformations['Carrier'],
  //     },
  //   ],
  // },
  // [RFNs.r35]: {
  //   cielo: `${RFNs.r35}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r32,
  //       rotation: ArrowRotation.Left,
  //       position: ArrowPosition.Left(3),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r37,
  //       rotation: ArrowRotation.Custom(-60),
  //       position: ArrowPosition.Custom(5, -50),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r36,
  //       rotation: ArrowRotation.Custom(-60),
  //       position: ArrowPosition.Custom(6, -100),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Up,
  //       position: '-1.8 1 1.3',
  //       content: StandInformations['BriCas_Industrial_Solutions,_S._C.'],
  //     },
  //   ],
  // },
  // [RFNs.r36]: {
  //   cielo: `${RFNs.r36}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r37,
  //       rotation: ArrowRotation.Custom(10),
  //       position: ArrowPosition.Custom(5, 10),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r35,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(6, 80),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r39,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(5, -170),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       rotation: '0 30 0',
  //       position: '-1.5 1 -1.8',
  //       content: StandInformations['Blue_Yonder'],
  //     },
  //   ],
  // },
  // [RFNs.r37]: {
  //   cielo: `${RFNs.r37}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.tiltedRightStand,
  //   navegadores: [
  //     {
  //       to: RFNs.r35,
  //       rotation: ArrowRotation.Left,
  //       position: ArrowPosition.Left(3),
  //       type: 'arrow',
  //     },
  //     {
  //       to: RFNs.r36,
  //       rotation: ArrowRotation.Custom(210),
  //       position: ArrowPosition.Custom(2.5, 210),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '-2 1 -1.3',
  //       rotation: '0 70 0',
  //       content: StandInformations['_Valley_International_Supply'],
  //     },
  //   ],
  // },
  // [RFNs.r38]: {
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   cielo: `${RFNs.r38}.jpg`,
  //   navegadores: [],
  //   totems: [],
  // },
  // [RFNs.r39]: {
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   cielo: `${RFNs.r39}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r36,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(5, -10),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r35,
  //       rotation: ArrowRotation.Custom(45),
  //       position: ArrowPosition.Custom(7, 30),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r40,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(5, -170),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       rotation: '0 30 0',
  //       position: '-1.5 1 -1.8',
  //       content: StandInformations['Base22'],
  //     },
  //   ],
  // },
  // [RFNs.r40]: {
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   cielo: `${RFNs.r40}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r39,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(5, -10),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r42,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(5, -170),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r41,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(4, 40),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r43,
  //       rotation: ArrowRotation.Custom(120),
  //       position: ArrowPosition.Custom(4, 120),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       rotation: '0 30 0',
  //       position: '-1.5 1 -1.8',
  //       content: StandInformations['Arca_Continental'],
  //     },
  //   ],
  // },
  // [RFNs.r41]: {
  //   cielo: `${RFNs.r41}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.eastToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r39,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(4, -20),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r40,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(2, -135),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r43,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(6, 120),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       rotation: '0 190 0',
  //       position: '0.2 1 1.8',
  //       content: StandInformations['Mondelez_International'],
  //     },
  //   ],
  // },
  // [RFNs.r42]: {
  //   cielo: `${RFNs.r42}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.westToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r40,
  //       rotation: ArrowRotation.Custom(0),
  //       position: ArrowPosition.Custom(5, -10),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r43,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(5, 80),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       rotation: '0 30 0',
  //       position: '-1.5 1 -1.8',
  //       content: StandInformations['American_Axle_Manufacturing'],
  //     },
  //   ],
  // },
  // [RFNs.r43]: {
  //   cielo: `${RFNs.r43}.jpg`,
  //   rotacionCielo: SkyRotationNormalization.southToNorth,
  //   navegadores: [
  //     {
  //       to: RFNs.r42,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(7, -110),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r44,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(2, 135),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r41,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(6, -45),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '1.8 1 -1.3',
  //       rotation: '0 -60 0',
  //       content: StandInformations['Tokai_Rika_México'],
  //     },
  //   ],
  // },
  // [RFNs.r44]: {
  //   cielo: `${RFNs.r44}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r45,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(3, 80),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r43,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(3, -60),
  //       type: 'hotspot',
  //     },
  //   ],
  //   totems: [
  //     {
  //       ...TotemPositions.Up,
  //       position: '-1.5 1 1.3',
  //       content: StandInformations['Accenture'],
  //     },
  //   ],
  // },
  // [RFNs.r45]: {
  //   rotacionCielo: SkyRotationNormalization.southToNorth,
  //   cielo: `${RFNs.r45}.jpg`,
  //   navegadores: [
  //     {
  //       to: RFNs.r43,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(5, -100),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r44,
  //       rotation: ArrowRotation.Custom(80),
  //       position: ArrowPosition.Custom(3, -120),
  //       type: 'hotspot',
  //     },
  //     {
  //       to: RFNs.r22,
  //       rotation: ArrowRotation.Left,
  //       position: ArrowPosition.Left(3),
  //       type: 'arrow',
  //     },
  //   ],
  //   totems: [
  //     {
  //       position: '1.8 1 -1.3',
  //       rotation: '0 -60 0',
  //       content: StandInformations['Schneider_Electric'],
  //     },
  //   ],
  // },
  // [RFNs.r46]: {
  //   cielo: `${RFNs.r46}.jpg`,
  //   navegadores: [],
  //   totems: [],
  // },
};

const buildRendersOwners = <T extends Record<string, Renders>>(t: T) => t;

export const RendersOwners = buildRendersOwners({
  FIME: 'render0002',
});

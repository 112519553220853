import { getChatId } from '@flfime/helpers/Misc';
import {
  compose,
  head,
  lensProp,
  over,
  pipe,
  set,
  split,
  toString,
} from 'ramda';
import { chatsCollection, messagesCollection } from '@flfime/services/firebase';
import { Chat, ChatFields, Message } from '@flfime/types/Chat';

const timestampLens = lensProp<Chat, 'updated'>('updated');
export const normalizeChatDate = over(timestampLens, toString);

class ChatAPI {
  constructor() {}
  async fetchChats(uid: string) {
    const ss = await chatsCollection.where(ChatFields.userId, '==', uid).get();
    const chats = ss.docs.map((doc) => doc.data() as Chat);
    const normalizedChats = chats.map(normalizeChatDate);
    return normalizedChats;
  }
  async openChat(uid: string, uname: string, cid: string, cname: string) {
    const chat: Chat = {
      companyId: cid,
      userId: uid,
      companyName: cname,
      updated: new Date(),
      username: uname,
      chatId: getChatId({ companyId: cid, userId: uid }),
      clientPendingMessages: 0,
      userPendingMessages: 0,
      latestMessage: '',
    };
    await chatsCollection.doc(chat.chatId).set(chat);
    return normalizeChatDate(chat);
  }
  async sendMessage({ chatId, createdAt, idAuthor, message }: Message) {
    await messagesCollection(chatId).add({
      chatId,
      createdAt,
      idAuthor,
      message,
    });
  }
  async clearPendingMessages(chatId: string) {
    chatsCollection.doc(chatId).update({ userPendingMessages: 0 });
  }
}

const ChatRepo = new ChatAPI();

export { ChatRepo };

import React from 'react';
import { Button, colors, makeStyles } from '@material-ui/core';
import { Warning, Error, Info, CheckCircleOutline } from '@material-ui/icons';
import { ModalContext } from '@flfime/components/Modal/ModalCtx';
import { Title } from '@flfime/components/Text/Title';
import { useContext } from 'react';

type InofrmativeModalProps = {
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
};

const useStyles = makeStyles({
  success: {
    color: colors.green[400],
    fontSize: '5em',
  },
  warning: {
    color: colors.amber[400],
    fontSize: '5em',
  },
  error: {
    color: colors.red[400],
    fontSize: '5em',
  },
  info: {
    color: colors.blue[600],
    fontSize: '5em',
  },
});

export const InformativeModal = ({ type, message }: InofrmativeModalProps) => {
  const classes = useStyles();
  const { closeModal } = useContext(ModalContext);
  return (
    <div className="bg-white p-4 rounded-sm flex flex-col items-center justify-center">
      {type === 'error' && (
        <Error classes={{ fontSizeLarge: classes.error }} fontSize="large" />
      )}
      {type === 'info' && (
        <Info classes={{ fontSizeLarge: classes.info }} fontSize="large" />
      )}
      {type === 'warning' && (
        <Warning
          classes={{ fontSizeLarge: classes.warning }}
          fontSize="large"
        />
      )}
      {type === 'success' && (
        <CheckCircleOutline
          classes={{ fontSizeLarge: classes.success }}
          fontSize="large"
        />
      )}
      <Title type="h2">{message}</Title>
      <Button onClick={closeModal}>Cerrar</Button>
    </div>
  );
};

import { Button, useMediaQuery } from '@material-ui/core';
import { ModalContext } from '@flfime/components/Modal/ModalCtx';
import { InformativeModal } from '@flfime/components/Modal/Modals/InformativeModal';
import { AuthContext } from '@flfime/components/Routes/AuthProvider';
import { Paragraph } from '@flfime/components/Text/Paragraph';
import { fileListToArr, toBase64 } from '@flfime/helpers/Misc';
import { and, cond, always, not } from 'ramda';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationRepo } from '@flfime/services/Authentication/AuthenticationAPI';
import {
  currentUser,
  setCurrentUserCurriculum,
} from '@flfime/store/CurrentUser/slice';
import { Colors } from '@flfime/theme/Colors';
import { AsyncState } from '@flfime/types/OtherTypes';

type SelectPdfProps = {
  setSelectedPdf: Dispatch<SetStateAction<string | null>>;
  selectedPdf: string | null;
  openPdf: (s: string) => any;
};

export const SelectPdf = ({
  setSelectedPdf,
  openPdf,
  selectedPdf,
}: SelectPdfProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { openModalWith } = useContext(ModalContext);
  const [uploadingState, setUploadingState] = useState<AsyncState>(null);
  const user = useContext(AuthContext);
  const dispatch = useDispatch();
  const _currentUser = useSelector(currentUser);
  const handleFiles = async (fs: File[] | null | FileList) => {
    if (!fs) return;
    let files = fs;
    if (fs instanceof FileList) {
      files = fileListToArr(fs);
    }
    if (files.length > 1) {
      return openModalWith(
        <InformativeModal
          message="No puedes subir más de un archivo"
          type="error"
        />,
        'Normal'
      );
    }
    const base64pdf = await toBase64(files[0]);
    setSelectedPdf(base64pdf);
  };
  const uploadPdf = async () => {
    setUploadingState('loading');
    try {
      const ref = await AuthenticationRepo.uploadCurriculum(
        selectedPdf as string,
        user?.uid as string,
        user?.displayName as string,
        _currentUser?.matricula as string
      );
      setSelectedPdf(null);
      setUploadingState('successful');
      dispatch(setCurrentUserCurriculum(ref));
    } catch (error) {
      setUploadingState('rejected');
      return openModalWith(
        <InformativeModal
          message="Ocurrió un error al intentar subir tu curriculum"
          type="error"
        />,
        'Normal'
      );
    }
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDropAccepted: handleFiles,
    accept: 'application/pdf',
  });
  const isDragActiveAnd = and(isDragActive);
  const dropzoneMessage = cond([
    [always(isDragActiveAnd(!isDragReject)), always('Suelta el archivo aquí')],
    [
      always(isDragActiveAnd(isDragReject)),
      always('Por favor selecciona un archivo válido'),
    ],
    [
      always(not(isDragActiveAnd(!isDragReject))),
      always('Arrastra el pdf o haz click para seleccionar uno'),
    ],
  ]);
  return (
    <>
      {!isMobile ? (
        <div
          {...getRootProps()}
          className={`${
            isDragReject ? 'border-red-600' : 'border-primary'
          } primary h-24 p-8 text-center border border-dashed text-base`}
        >
          <input disabled={uploadingState === 'loading'} {...getInputProps()} />
          {dropzoneMessage()}
        </div>
      ) : (
        <Button
          disabled={uploadingState === 'loading'}
          style={{ backgroundColor: Colors.lightMain, color: 'white' }}
          variant="contained"
          component="label"
        >
          Subir curriculum
          <input
            type="file"
            hidden
            onChange={(e) => handleFiles(e.target.files)}
          />
        </Button>
      )}
      <Paragraph className="mt-2 pb-2 text-right">
        {selectedPdf ? (
          <>
            Has seleccionado
            <button
              onClick={() => openPdf(selectedPdf)}
              className="text-primary ml-1"
            >
              tu curriculum
            </button>
          </>
        ) : (
          'Selecciona un pdf'
        )}
      </Paragraph>
      <div className="flex justify-end">
        <Button
          onClick={uploadPdf}
          disabled={uploadingState === 'loading' || !selectedPdf}
          variant="outlined"
          color="primary"
        >
          {_currentUser?.curriculum
            ? `${
                uploadingState === 'loading' ? 'Actualizando...' : 'Actualizar'
              }`
            : `${uploadingState === 'loading' ? 'Subiendo...' : 'Subir'}`}
        </Button>
      </div>
    </>
  );
};

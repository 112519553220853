import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  MailOutlined,
  Visibility,
  VisibilityOff,
  Dialpad,
  AccountCircleOutlined,
  Lock,
  Phone,
} from '@material-ui/icons';
import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Link, Router, useHistory } from 'react-router-dom';
import { Paragraph } from '@flfime/components/Text/Paragraph';
import firebase from 'firebase/app';
import { getAuthErrorMessage } from '@flfime/services/firebaseErrors';
import { UserCredential } from '@flfime/types/FirebaseTypes';

type RegisterFormProps = {
  onSubmit: (formInputs: RegisterForm) => Promise<UserCredential>;
  onGoToLogin: () => any;
};

export type RegisterForm = {
  fullName: string;
  state: string;
  UANLgraduated: string;
  otherUniversiy: string;
  secondLanguageName: string;
  specifiedSecondLanguageName: string;
  secondLanguagePercentage: string;
  technicaAbilities: string;
  searchingVacancyType: string;
  lastJob: string;
  specifiedUniversity: string;
  dependency: string;
  career: string;
  eduEmail: string;
  matricula: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
};

const Spacing = () => <div className="py-2"></div>;

const matriculaRules = {
  required: {
    value: true,
    message: 'Por favor ingresa una matrícula',
  },
  maxLength: {
    value: 7,
    message: 'Una matrícula solo tiene hasta 7 digitos',
  },
};

export const RegisterForm = ({ onSubmit, onGoToLogin }: RegisterFormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    register,
    unregister,
    formState: { errors, isSubmitting },
    getValues,
    watch,
  } = useForm<RegisterForm>();
  const [showPassword, setShowPassword] = useState({
    confirmation: false,
    normal: false,
  });
  const [formError, setFormError] = useState({ hasError: false, message: '' });

  const onFormSubmit = async (input: RegisterForm) => {
    setFormError({ hasError: false, message: '' });
    try {
      await onSubmit(input);
      setFormError({
        hasError: false,
        message: '',
      });
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        setFormError({
          hasError: true,
          message: getAuthErrorMessage(error.name),
        });
      }
    }
    return;
  };

  console.log(errors);

  const uanlGraduated = watch('UANLgraduated');
  const otherUniversiy = watch('otherUniversiy');
  const secondLanguageName = watch('secondLanguageName');

  useEffect(() => {
    if (uanlGraduated === 'No') {
      setValue('otherUniversiy', null);
      setValue('matricula', '');
      unregister(['matricula', 'eduEmail', 'dependency','career']);
      register('otherUniversiy', {
        required: { value: true, message: 'Por favor llena el campo' },
      });
    } else {
      unregister(['otherUniversiy', 'specifiedUniversity']);
      register('matricula', matriculaRules);
      register('eduEmail', {
        required: { value: true, message: 'Por favor llena el campo' },
      });
      register('dependency', {
        required: { value: true, message: 'Por favor llena el campo' },
      });
      register('career', {
        required: { value: true, message: 'Por favor llena el campo' },
      });

    }
  }, [uanlGraduated]);

  useEffect(() => {
    if (otherUniversiy === 'Otra') {
      register('specifiedUniversity', {
        required: {
          value: true,
          message: 'Por favor llena el campo',
        },
      });
    } else {
      setValue('specifiedUniversity', '');
      unregister('specifiedUniversity');
    }
  }, [otherUniversiy]);

  useEffect(() => {
    if (secondLanguageName === 'Otro') {
      register('specifiedSecondLanguageName', {
        required: {
          value: true,
          message: 'Por favor llena el campo',
        },
      });
    } else {
      unregister('specifiedSecondLanguageName');
    }
  }, [secondLanguageName]);

  return (
    <div>
      <form className="flex flex-col" onSubmit={handleSubmit(onFormSubmit)}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: '0.5em' }}
          className={`mb-2 w-full ${
            formError.message ? 'text-red-500' : 'text-white'
          }`}
        >
          {formError.message || 'a'}
        </Typography>
        <Controller
          control={control}
          name="fullName"
          rules={{
            required: { value: true, message: 'Por favor ingresa tu nombre' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined color="disabled" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Nombre completo"
              {...field}
              helperText={
                error ? error?.message : 'Ingresa un correo electrónico'
              }
              error={!!error}
              inputProps={{ 'data-testid': 'register_fullname' }}
            />
          )}
        />
        <Spacing />
        <Controller
          control={control}
          name="email"
          rules={{
            required: { value: true, message: 'Por favor ingresa tu correo' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined color="disabled" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Correo electrónico"
              {...field}
              helperText={
                error ? error?.message : 'Ingresa un correo electrónico'
              }
              error={!!error}
              inputProps={{ 'data-testid': 'register_email' }}
            />
          )}
        />
        <Spacing />
        <Controller
          control={control}
          name="phoneNumber"
          rules={{
            required: { value: true, message: 'Por favor ingresa tu número' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone color="disabled" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Teléfono"
              name="phoneNumber"
              {...field}
              helperText={error ? error?.message : 'Ingresa un tu número'}
              error={!!error}
            />
          )}
        />

        <Spacing />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor ingresa una contraseña',
            },
            min: {
              value: 6,
              message: 'Tu contraseña debe tener por lo menos 6 caracteres',
            },
          }}
          name="password"
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type={showPassword.normal ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword((sp) => ({ ...sp, normal: !sp.normal }))
                      }
                      edge="end"
                    >
                      {showPassword.normal ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Contraseña"
              {...field}
              helperText={error ? error?.message : 'Ingresa una contraseña'}
              error={!!error}
              inputProps={{ 'data-testid': 'register_password' }}
            />
          )}
        />
        <Spacing />
        <Controller
          control={control}
          rules={{
            validate: (val) =>
              val === getValues('password')
                ? undefined
                : 'Las contraseñas no coinciden',
            required: {
              value: true,
              message: 'Por favor confirma tu contraseña',
            },
          }}
          name="confirmPassword"
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type={showPassword.confirmation ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword((sp) => ({
                          ...sp,
                          confirmation: !sp.confirmation,
                        }))
                      }
                      edge="end"
                    >
                      {showPassword.confirmation ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Confirmar contraseña"
              {...field}
              helperText={
                error
                  ? errors.confirmPassword?.message
                  : 'Confirma tu contraseña'
              }
              error={!!error}
              inputProps={{ 'data-testid': 'register_confirmpassword' }}
            />
          )}
        />
        <Spacing />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor llena el campo',
            },
          }}
          name="state"
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              color="primary"
              size="small"
              label="Estado"
              variant="outlined"
              helperText={error ? error?.message : 'Selecciona un estado'}
              error={!!error}
              SelectProps={{
                ...field,
              }}
            >
              <MenuItem disabled value={null}>
                Selecciona tu respuesta
              </MenuItem>
              <MenuItem value="Aguascalientes"> Aguascalientes </MenuItem>
              <MenuItem value="Baja California"> Baja California </MenuItem>
              <MenuItem value="Baja California Sur">
                Baja California Sur
              </MenuItem>
              <MenuItem value="Campeche"> Campeche </MenuItem>
              <MenuItem value="Chiapas"> Chiapas </MenuItem>
              <MenuItem value="Chihuahua"> Chihuahua </MenuItem>
              <MenuItem value="Coahuila">Coahuila</MenuItem>
              <MenuItem value="Colima"> Colima </MenuItem>
              <MenuItem value="Ciudad de México"> Ciudad de México </MenuItem>
              <MenuItem value="Durango"> Durango </MenuItem>
              <MenuItem value="Guanajuato"> Guanajuato </MenuItem>
              <MenuItem value="Guerrero"> Guerrero </MenuItem>
              <MenuItem value="Hidalgo"> Hidalgo </MenuItem>
              <MenuItem value="Jalisco"> Jalisco </MenuItem>
              <MenuItem value="Estado de Mexico"> Estado de Mexico </MenuItem>
              <MenuItem value="Michoacan">Michoacan</MenuItem>
              <MenuItem value="Morelos"> Morelos </MenuItem>
              <MenuItem value="Nayarit"> Nayarit </MenuItem>
              <MenuItem value="Nuevo Leon"> Nuevo Leon </MenuItem>
              <MenuItem value="Oaxaca"> Oaxaca </MenuItem>
              <MenuItem value="Puebla"> Puebla </MenuItem>
              <MenuItem value="Queretaro">Queretaro</MenuItem>
              <MenuItem value="Quintana Roo"> Quintana Roo </MenuItem>
              <MenuItem value="San Luis Potosi"> San Luis Potosi </MenuItem>
              <MenuItem value="Sinaloa"> Sinaloa </MenuItem>
              <MenuItem value="Sonora"> Sonora </MenuItem>
              <MenuItem value="Tabasco"> Tabasco </MenuItem>
              <MenuItem value="Tamaulipas"> Tamaulipas </MenuItem>
              <MenuItem value="Tlaxcala"> Tlaxcala </MenuItem>
              <MenuItem value="Veracruz">Veracruz</MenuItem>
              <MenuItem value="Yucatan"> Yucatan </MenuItem>
              <MenuItem value="Zacatecas"> Zacatecas </MenuItem>
            </TextField>
          )}
        />
        <Spacing />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor llena el campo',
            },
          }}
          name="UANLgraduated"
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              color="primary"
              size="small"
              label="Estudiante y/o Egresados de la UANL"
              variant="outlined"
              helperText={error ? error?.message : 'Selecciona tu respuesta'}
              error={!!error}
              SelectProps={{
                ...field,
              }}
            >
              <MenuItem value="Sí">Sí</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          )}
        />
        <Spacing />

        {uanlGraduated === 'No' && (
          <>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor llena el campo',
                },
              }}
              name="otherUniversiy"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  color="primary"
                  size="small"
                  label="Universidad"
                  variant="outlined"
                  helperText={
                    error ? error?.message : 'Selecciona tu respuesta'
                  }
                  error={!!error}
                  SelectProps={{
                    ...field,
                  }}
                >
                  <MenuItem disabled value={null}>
                    Selecciona tu respuesta
                  </MenuItem>
                  <MenuItem value="Universidad de monterrey">
                    Universidad de Monterrey
                  </MenuItem>
                  <MenuItem value="Universidad del Norte">
                    Universidad del Norte
                  </MenuItem>
                  <MenuItem value="Universidad del Valle de México">
                    Universidad del Valle de México
                  </MenuItem>
                  <MenuItem value="Universidad Regiomontana">
                    Universidad Regiomontana
                  </MenuItem>
                  <MenuItem value="Tec de Monterrey">Tec de Monterrey</MenuItem>
                  <MenuItem value="Otra">Otra</MenuItem>
                </TextField>
              )}
            />
            <Spacing />
          </>
        )}
        {otherUniversiy === 'Otra' && (
          <>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor llena el campo',
                },
              }}
              name="specifiedUniversity"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  color="primary"
                  size="small"
                  label="Otra universidad"
                  variant="outlined"
                  helperText={
                    error ? error?.message : 'Especifíca la universidad'
                  }
                  error={!!error}
                  InputProps={{
                    ...field,
                  }}
                ></TextField>
              )}
            />
            <Spacing />
          </>
        )}

        {uanlGraduated === 'Sí' && (
          <>
            <Controller
              control={control}
              rules={matriculaRules}
              name="matricula"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  color="primary"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Dialpad color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  {...field}
                  label="Matrícula"
                  helperText={error ? error?.message : 'Ingresa una matrícula'}
                  error={!!error}
                />
              )}
            />
            <Spacing />
          </>
        )}

        {uanlGraduated === 'Sí' && (
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Por favor ingresa tu correo universitario',
              },
            }}
            name="eduEmail"
            render={({ field, fieldState: { error } }) => (
              <TextField
                color="primary"
                size="small"
                variant="outlined"
                {...field}
                label="Correo universitario"
                helperText={error ? error?.message : 'Ingresa un correo'}
                error={!!error}
              />
            )}
          />
        )}

        {uanlGraduated === 'Sí' && (
          <>
            <Spacing />
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor selecciona tu dependencia',
                },
              }}
              name="dependency"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  color="primary"
                  size="small"
                  label="Dependencia"
                  variant="outlined"
                  helperText={
                    error ? error?.message : 'Selecciona tu dependencia'
                  }
                  error={!!error}
                  SelectProps={{
                    ...field,
                  }}
                  inputProps={{ 'data-testid': 'register_password' }}
                >
                  <MenuItem value="Artes Visuales">Artes Visuales</MenuItem>
                  <MenuItem value="Ciencias Biológicas">
                    Ciencias Biológicas
                  </MenuItem>
                  <MenuItem value="Ciencias de la Comunicación">
                    Ciencias de la Comunicación
                  </MenuItem>
                  <MenuItem value="Ciencias de la Tierra">
                    Ciencias de la Tierra
                  </MenuItem>
                  <MenuItem value="Ciencias Físico- Matemáticas">
                    Ciencias Físico- Matemáticas
                  </MenuItem>
                  <MenuItem value="Ciencias Forestales">
                    Ciencias Forestales
                  </MenuItem>
                  <MenuItem value="Ciencias Políticas y Relaciones Internacionales">
                    Ciencias Políticas y Relaciones Internacionales
                  </MenuItem>
                  <MenuItem value="Ciencias Químicas">
                    Ciencias Químicas
                  </MenuItem>
                  <MenuItem value="Contaduría Pública y Adminstración">
                    Contaduría Pública y Adminstración
                  </MenuItem>
                  <MenuItem value="Derecho y Criminología">
                    Derecho y Criminología
                  </MenuItem>
                  <MenuItem value="Economía">Economía</MenuItem>
                  <MenuItem value="Enfermería">Enfermería</MenuItem>
                  <MenuItem value="Facultad de Ingeniería Mecánica y Eléctrica">
                    Facultad de Ingeniería Mecánica y Eléctrica
                  </MenuItem>
                  <MenuItem value="Folosofía y Letras">
                    Folosofía y Letras
                  </MenuItem>
                  <MenuItem value="Ingeniería Civil">Ingeniería Civil</MenuItem>
                  <MenuItem value="Medicina">Medicina</MenuItem>
                  <MenuItem value="Medicina Veterinaria y Zootecnia">
                    Medicina Veterinaria y Zootecnia
                  </MenuItem>
                  <MenuItem value="Música">Música</MenuItem>
                  <MenuItem value="Odontología">Odontología</MenuItem>
                  <MenuItem value="Organización Deportiva">
                    Organización Deportiva
                  </MenuItem>
                  <MenuItem value="Psicología">Psicología</MenuItem>
                  <MenuItem value="Salud Pública y Nutrición">
                    Salud Pública y Nutrición
                  </MenuItem>
                  <MenuItem value="Trabajo Social y Desarrollo Humano">
                    Trabajo Social y Desarrollo Humano
                  </MenuItem>
                </TextField>
              )}
            />
            <Spacing />
          </>
        )}


{uanlGraduated === 'Sí' && (
          <>
            <Spacing />
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor selecciona tu dependencia',
                },
              }}
              name="career"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  color="primary"
                  size="small"
                  label="Carrera"
                  variant="outlined"
                  helperText={
                    error ? error?.message : 'Selecciona tu carrera'
                  }
                  error={!!error}
                  SelectProps={{
                    ...field,
                  }}
                  inputProps={{ 'data-testid': 'register_password' }}
                >
                  <MenuItem value="IME">IME</MenuItem>
                  <MenuItem value="IMA">IMA</MenuItem>
                  <MenuItem value="IAS">
                  IAS
                  </MenuItem>
                  <MenuItem value="IEC">
                  IEC
                  </MenuItem>
                  <MenuItem value="IEA">
                  IEA
                  </MenuItem>
                  <MenuItem value="IMT">
                  IMT
                  </MenuItem>
                  <MenuItem value="IMF">
                  IMF
                  </MenuItem>
                  <MenuItem value="IMTC">
                  IMTC
                  </MenuItem>
                  <MenuItem value="IAE">
                  IAE
                  </MenuItem>
                  <MenuItem value="ITS">
                  ITS
                  </MenuItem>
                  <MenuItem value="IB">IB</MenuItem>
                  <MenuItem value="Otro">Otro</MenuItem>
                  
                </TextField>
              )}
            />
            <Spacing />
          </>
        )}

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor selecciona tu dependencia',
            },
          }}
          name="secondLanguageName"
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              color="primary"
              size="small"
              label="Lenguaje"
              variant="outlined"
              helperText={error ? error?.message : 'Segundo lenguaje'}
              error={!!error}
              {...field}
            >
              <MenuItem value="Inglés">Inglés</MenuItem>
              <MenuItem value="Alemán">Alemán</MenuItem>
              <MenuItem value="Francés">Francés</MenuItem>
              <MenuItem value="Coreano">Coreano</MenuItem>
              <MenuItem value="Chino">Chino</MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </TextField>
          )}
        />
        <Spacing />

        {secondLanguageName === 'Otro' && (
          <>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor llena el campo',
                },
              }}
              name="specifiedSecondLanguageName"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  color="primary"
                  size="small"
                  label="Otro lenguaje"
                  variant="outlined"
                  helperText={error ? error?.message : 'Otro lenguje'}
                  error={!!error}
                  SelectProps={{
                    ...field,
                  }}
                />
              )}
            />
            <Spacing />
          </>
        )}

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor selecciona tu dependencia',
            },
          }}
          name="secondLanguagePercentage"
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              select
              size="small"
              label="Nivel"
              variant="outlined"
              helperText={error ? error?.message : 'Nivel'}
              error={!!error}
              SelectProps={{ ...field }}
            >
              <MenuItem disabled value={null}>
                Selecciona tu respuesta
              </MenuItem>
              <MenuItem value="Básico"> Básico: 0%-49%</MenuItem>
              <MenuItem value="Intermedio"> Intermedio: 50%-79%</MenuItem>
              <MenuItem value="Avanzado"> Avanzado: 80%-100%</MenuItem>
            </TextField>
          )}
        />
        <Spacing />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor llena el campo',
            },
          }}
          name="technicaAbilities"
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              label="Habilidades técnicas/administrativas"
              variant="outlined"
              helperText={error ? error?.message : 'Selecciona una opción'}
              error={!!error}
              {...field}
            />
          )}
        />
        <Spacing />
        <Controller
          control={control}
          name="lastJob"
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              label="Último puesto de trabajo"
              variant="outlined"
              helperText={error ? error?.message : 'Selecciona una opción'}
              error={!!error}
              {...field}
            />
          )}
        />
        <Spacing />
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Por favor selecciona una opción',
            },
          }}
          name="searchingVacancyType"
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              select
              size="small"
              label="Tipo de vacante que buscas"
              variant="outlined"
              helperText={error ? error?.message : 'Selecciona una opción'}
              error={!!error}
              SelectProps={{ ...field }}
            >
              <MenuItem disabled value={null}>
                Selecciona tu respuesta
              </MenuItem>
              <MenuItem value="Técnica">Técnica</MenuItem>
              <MenuItem value="Prácticas profesionales">
                Prácticas profesionales
              </MenuItem>
              <MenuItem value="Servicio social">Servicio social</MenuItem>
              <MenuItem value="Pasante">Pasante</MenuItem>
              <MenuItem value="Titulado">Titulado</MenuItem>
              <MenuItem value="Posgrado">Posgrado</MenuItem>
            </TextField>
          )}
        />
        <Spacing />
        <Button
          variant="contained"
          disabled={isSubmitting}
          color="primary"
          onClick={handleSubmit(onFormSubmit)}
          data-testid="register_submit"
          style={{ color: 'white' }}
        >
          REGISTRARSE
        </Button>
      </form>
      <button
        className="mb-2 mt-4 text-right text-gray-500"
        onClick={onGoToLogin}
      >
        <Paragraph>
          Ya tienes una cuenta ?{' '}
          <span className="text-primary">Inicia sesión</span>
        </Paragraph>
        <Typography
          style={{ textAlign: 'left', fontSize: '0.4em', marginTop: '0.7em' }}
        >
          La Facultad de Ingeniería Mecánica y Eléctrica, como responsable del
          uso de información confidencial que en ella se realizan, está
          comprometida con la protección de los datos personales. Con su
          acuerdo, usamos cookies o tecnologías similares para almacenar,
          acceder y procesar datos personales como su visita en este sitio web.
          También nos permiten proporcionar estadísticas en departamentos
          internos, pudiendo ejercitar los derechos de acceso, cancelación y
          rectificación de la información, así como de oposición.
        </Typography>
      </button>
    </div>
  );
};

import React from 'react';
import { useOnHover } from '@flfime/hooks/useOnHover';
import { Link } from 'react-router-dom';

type AnimatedLinkProps = {
  color: string;
  hoverColor: string;
  title: string;
  description: string;
  navigate: () => any;
  disabled?: boolean;
};
export const AnimatedLink = ({
  color,
  hoverColor,
  title,
  description,
  navigate,
  disabled = false,
}: AnimatedLinkProps) => {
  return (
    <div className="relative">
      <button
        disabled={disabled}
        onClick={navigate}
        className="font-extrabold text-left text-lg md:text-2xl relative group"
      >
        <h1 style={{ color }} className="">
          {title}
        </h1>
        <h1
          style={{ color: hoverColor }}
          className="absolute top-0 text-white scale-0 transform transition-all duration-500 ease-in-out group-hover:scale-110"
        >
          {title}
        </h1>
      </button>
      <h1
        style={{ color }}
        className="text-base md:text-2xl text-primaryLight opacity-80"
      >
        {description}
      </h1>
    </div>
  );
};

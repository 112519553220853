import React from 'react';
type DotProps = {
  selected: boolean;
  onClick?: () => any;
  className?: string;
};
export const Dot = ({ selected, onClick, className }: DotProps) => {
  return (
    <button
      onClick={onClick}
      className={`w-4 h-4 md:w-6 md:h-6 rounded-full border-white transition-all border-2 duration-200 ${
        selected ? 'bg-white' : 'bg-transparent'
      } ${className}`}
    ></button>
  );
};

import React from 'react';
import {
  Drawer,
  IconButton,
  Divider,
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronRight, ExitToApp } from '@material-ui/icons';
import {
  navigationDrawerIsOpen,
  toggleNavigationDrawer,
} from '@flfime/store/NavigationDrawer/slice';
import { NavbarRoutes } from '@flfime/App';
import { Link } from 'react-router-dom';
import { Paragraph } from '@flfime/components/Text/Paragraph';
import { Title } from '@flfime/components/Text/Title';
import { auth } from '@flfime/services/firebase';
import { useContext } from 'react';
import { AuthContext } from '@flfime/components/Routes/AuthProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {},
  drawerPaper: {
    [theme.breakpoints.up('xs')]: {
      width: '20em',
      flexShrink: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
      flexShrink: 0,
    },
  },
}));

export const NavigationDrawer = () => {
  const isOpen = useSelector(navigationDrawerIsOpen);
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useContext(AuthContext);
  const _toggleNavigationDrawer = () => {
    dispatch(toggleNavigationDrawer());
  };
  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor="right"
      open={isOpen}
      onClose={_toggleNavigationDrawer}
    >
      <div className="flex p-1">
        <IconButton onClick={_toggleNavigationDrawer}>
          <ChevronRight />
        </IconButton>
      </div>
      <Divider />
      <List>
        {NavbarRoutes.map((nr) => (
          <ListItem onClick={_toggleNavigationDrawer} button key={nr.path}>
            <Link className="w-full h-full" to={nr.path}>
              <Title type="h2">{nr.routeName}</Title>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      {user && (
        <List>
          <ListItem onClick={() => auth.signOut()} button>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </ListItem>
        </List>
      )}
    </Drawer>
  );
};

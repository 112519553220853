import firebase from 'firebase/app';
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { has } from 'ramda';

export interface FirebaseEntity {
  id: string;
}
type ThunkState = null | 'pending' | 'success' | 'error';

export const CreateFirebaseCRUD = <
  CreateDTO,
  ReadDTO extends FirebaseEntity,
  UpdateDTO extends Record<keyof CreateDTO, unknown>
>({
  collectionName,
  collection,
  nameOfTimestamps,
}: {
  collection: firebase.firestore.CollectionReference<ReadDTO>;
  collectionName: string;
  nameOfTimestamps: string[];
}) => {
  const namespace = `${collectionName}CRUD`;
  const readData = createAsyncThunk(`${namespace}/read`, async () => {
    const snapshot = await collection.get();
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const normalizedData = data.map((d) => {
      nameOfTimestamps.forEach((t) => {
        if (
          has('createdAt', d) &&
          d['createdAt'] instanceof firebase.firestore.Timestamp
        ) {
          d.createdAt = d.createdAt.toDate().getTime().toString();
        }
      });
      return d;
    });
    return normalizedData;
  });
  const deleteData = createAsyncThunk(
    `${namespace}/delete`,
    async (id: string) => {
      collection.doc(id).delete();
    }
  );
  const updateData = createAsyncThunk(
    `${namespace}/update`,
    async ({ id, ...newData }: UpdateDTO & { id: string }) =>
      collection.doc(id).update(newData)
  );
  const entitiesAdapter = createEntityAdapter<ReadDTO & FirebaseEntity>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id),
  });
  const slice = createSlice({
    name: namespace,
    initialState: {
      readState: null as ThunkState,
      deleteState: null as ThunkState,
      updateState: null as ThunkState,
      ...entitiesAdapter.getInitialState(),
    },
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(readData.pending, (state) => {
        state.readState = 'pending';
      });
      builder.addCase(readData.fulfilled, (state, { payload: entities }) => {
        state.readState = 'success';
        // @ts-ignore
        entitiesAdapter.setAll(state, entities);
      });
      builder.addCase(readData.rejected, (state, { error }) => {
        state.readState = 'error';
      });
    },
  });
  const reducer = slice.reducer;
  type state = ReturnType<typeof reducer>;
  return { readData, deleteData, updateData, reducer };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const cameraSlice = createSlice({
    name: 'camera',
    initialState: {
        zoom: 1,
    },
    reducers: {
        setZoom: (state, { payload }: PayloadAction<number> ) => {
            state.zoom = payload
        }
    }
})

export const { setZoom } = cameraSlice.actions
import { compose, curry, last, reduce, split } from 'ramda';
import { Theme } from '@emotion/react';

export const getColor = (c: keyof Theme['color']) => ({
  theme,
}: {
  theme: Theme;
}) => theme.color[c];

export const getMq = (mq: keyof Theme['devices']) => ({
  theme,
}: {
  theme: Theme;
}) => theme.devices[mq];

export const areEqual = curry(
  (a: string, b: string, c: string) => a === b && b === c
);

export const flatAccumulatorForCharacter = curry(
  (l: string, pv: string, cv: string) =>
    areEqual(last(pv), cv, l) ? `${pv}` : `${pv}${cv}`
);

export const flattenSlashbar = compose<string, string[], string>(
  reduce(flatAccumulatorForCharacter('/'), ''),
  split('')
);

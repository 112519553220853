import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@flfime/store/store';

import { music } from './Song';

export const musicSlice = createSlice({
  name: 'music',
  initialState: {
    paused: music.audio.paused,
  },
  reducers: {
    toggleMusic: (state) => {
      if (state.paused) {
        music.play();
      } else {
        music.pause();
      }
      state.paused = !state.paused;
    },
    startMusic: (state) => {
      music.play();
      state.paused = false;
    },
  },
});

export const { startMusic, toggleMusic } = musicSlice.actions;

export const musicPaused = (state: RootState) => state.music.paused;

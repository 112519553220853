import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { AuthProvider } from '@flfime/components/Routes/AuthProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '@flfime/store/store';
import { Colors } from '@flfime/theme/Colors';
import App from './App';
import { ModalProvider } from './components/Modal/ModalCtx';
import '@flfime/styles/main.css';
import '@flfime/styles/animations.css';
import { Theme, ThemeProvider } from '@emotion/react';
import { WithThemeProvider } from '@flfime/theme/WithThemeProvider';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <WithThemeProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </WithThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

import { always, compose, cond, curry, isEmpty, match, T } from "ramda";

const matchesRegex = (regEx: RegExp) => compose<string, string[], boolean>(isEmpty,match(regEx))

export const getAuthErrorMessage = cond<string, string>([
    [matchesRegex(/email-already-exists/g), always('El correo ya está registrado')],
    [matchesRegex(/invalid-email/g), always('El correo ingresado no es válido')],
    [matchesRegex(/invalid-password/g), always('Contraseña inválida, debe de ser de al menos 6 caracteres')],
    [matchesRegex(/user-not-found/g), always('Este correo aún no está registrado')],
    [T, always('Error, por favor intentalo de nuevo')],
])
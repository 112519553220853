import styled from '@emotion/styled';

export const Container = styled.div`
  ${({ theme }) => theme.devices.mobileSm} {
    padding: 0 ${({ theme }) => theme.contentSpacing.sm};
  }
  ${({ theme }) => theme.devices.tablet} {
    padding: 0 ${({ theme }) => theme.contentSpacing.md};
  }
`;

import React from 'react';
import {
  Slide,
  Tooltip,
  Zoom,
  Grow,
  useMediaQuery,
  withStyles,
  Button,
} from '@material-ui/core';
import { BurgerBtn } from '@flfime/pages/Home/components/BurgerBtn/BurgerBtn';
import Particles from 'react-tsparticles';
import particles from './particles.json';
import './styles/styles.css';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { lazy, Suspense, useContext, useRef, useState } from 'react';
import { sleep } from '@flfime/helpers/Sleep';
import { AnimatedLink } from '@flfime/pages/Home/components/AnimatedLink/AnimatedLink';
import { Colors } from '@flfime/theme/Colors';
import { LoginForm } from '@flfime/components/Authentication/Login/LoginForm';
import { InformativeModal } from '@flfime/components/Modal/Modals/InformativeModal';
import { RegisterForm } from '@flfime/components/Authentication/Register/RegisterForm';
import { AuthenticationRepo } from '@flfime/services/Authentication/AuthenticationAPI';
import { Title } from '@flfime/components/Text/Title';
import { useDispatch } from 'react-redux';
import { ModalContext } from '@flfime/components/Modal/ModalCtx';
import { setCurrentUser } from '@flfime/store/CurrentUser/slice';
import { AuthContext } from '@flfime/components/Routes/AuthProvider';
import { UploadCurriculum } from '@flfime/pages/Home/components/UploadCurriculum/UploadCurriculum';
import { auth } from '@flfime/services/firebase';
import { getMq, LoadingScreenA, Paragraph } from '@oop/ui';
import { videoLinkToEmbedLink } from '@oop/data';
import { css, keyframes, useTheme } from '@emotion/react';
import { VideosModal } from '@flfime/features/Videos/components/VideosModal/VideosModal';
import { ReactComponent as Cogs } from '../../assets/cog.svg';
import { Carousel } from '@flfime/components/Carousel/Carousel';
import { nullifyObjectUndefinedValues } from '@flfime/helpers/nullifyObjectUndefinedValues';
const VirtualExperience = lazy(
  () => import('@flfime/pages/VirtualExperience/VirtualExperience')
);
const Lab = lazy(() => import('@flfime/pages/Lab/Lab'));
const Fime3D = lazy(() => import('@flfime/pages/Fime3D/Fime3D'));
const Companies = lazy(() => import('@flfime/pages/Companies/Companies'));

const BetterTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#222222',
    fontSize: '0.8em',
  },
}))(Tooltip);
type MenuSubRoutes = null | 'login' | 'register' | 'curriculum';

const rotateConstantly = keyframes`
  100% {
    transform: rotate(360deg);
  }

`;

export const Home2 = () => {
  const [showLinks, setShowLinks] = useState(false);
  const [inMenu, setInMenu] = useState(false);
  const [inTransition, setInTransition] = useState(false);
  const [menuSubRoute, setMenuSubRoute] = useState<MenuSubRoutes>(null);
  const { openModalWith } = useContext(ModalContext);

  const { devices } = useTheme();

  const mobile = useMediaQuery('(max-width: 768px)');
  const mobileSS = useMediaQuery('(max-width: 321px)');
  const parent = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useContext(AuthContext);

  const shouldShowLinks = showLinks && menuSubRoute === null;

  const toggleMenu = async () => {
    setInMenu((t) => !t);
    if (showLinks === true) setShowLinks(false);
    await sleep(500);
    if (showLinks === false) setShowLinks(true);
  };
  const goToRoute = async (r: string) => {
    setInTransition(true);
    await sleep(1000);
    toggleMenu();
    await sleep(200);
    history.push(r);
    setInTransition(false);
  };
  const onLogin = async (email: string, password: string) => {
    const { currentUser } = await AuthenticationRepo.loginUser({
      email,
      password,
    });
    dispatch(setCurrentUser(currentUser));
    openModalWith(
      <InformativeModal message="Has iniciado sesión" type="info" />,
      'Normal'
    );
    setMenuSubRoute('curriculum');
  };
  const onRegister = async (formData: RegisterForm) => {
    // console.log(nullifyObjectUndefinedValues(formData));
    // return;
    const { userCred, currentUser } = await AuthenticationRepo.registerUser(
      nullifyObjectUndefinedValues(formData) as RegisterForm
    );
    dispatch(setCurrentUser(currentUser));
    openModalWith(
      <InformativeModal
        message="Has iniciado sesión, por favor confirma tu correo"
        type="info"
      />,
      'Normal'
    );
    setMenuSubRoute('curriculum');
    return userCred;
  };
  const signOut = () => {
    auth.signOut();
    setMenuSubRoute(null);
    openModalWith(
      <InformativeModal message="Has cerrado sesión" type="info" />,
      'Normal'
    );
  };
  return (
    <div
      ref={parent}
      className="h-screen w-screen flex flex-col items-center relative justify-center overflow-y-auto overflow-x-hidden"
    >
      {/* Transition from menu to page */}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        key="transition_bg"
        in={inTransition}
        classNames="slideinout"
        timeout={1000}
      >
        <div className="absolute flex items-center justify-center h-full w-full bg-primaryLight z-[80]"></div>
      </CSSTransition>
      {/* Transition from menu to page end */}
      {/* Menu container */}
      <Slide
        mountOnEnter
        unmountOnExit
        in={inMenu}
        timeout={500}
        direction="up"
      >
        <div className="h-screen w-screen top-0 left-0 fixed bg-tertiaryLightPlus z-[1300] grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1">
          <div
            className={`grid w-full left_side text-4xl relative ${
              menuSubRoute !== null ? 'row-start-1 row-end-3' : ''
            }`}
          >
            <Slide
              unmountOnExit
              in={shouldShowLinks}
              timeout={300}
              direction="right"
            >
              <div className="bg-tertiaryLight relative flex items-end p-4">
                {!user && (
                  <div className="absolute w-full top-4 right-0 text-left md:text-right  text-sm text-gray-400 px-4">
                    <i className="fas fa-user-lock pr-1"></i>
                    <p className="inline">
                      Iniciar sesión para ver el recorrido
                    </p>
                  </div>
                )}
                <AnimatedLink
                  // disabled={!user}
                  disabled
                  navigate={() => {
                    if (!user?.emailVerified)
                      return openModalWith(
                        <InformativeModal
                          message="Por favor verifica tu correo"
                          type="info"
                        />,
                        'Normal'
                      );
                    goToRoute(`/recorrido`);
                  }}
                  title="Recorrido"
                  description="Visita el recorrido virtual"
                  color={Colors.main}
                  hoverColor="white"
                />
              </div>
            </Slide>
            <Slide
              unmountOnExit
              in={shouldShowLinks}
              timeout={300}
              direction="right"
            >
              <div className="bg-tertiary flex items-end p-4">
                <AnimatedLink
                  navigate={() => goToRoute(`/fime3d`)}
                  title="Fime3D"
                  description="Revisa nuestro trabajo"
                  color="white"
                  hoverColor={Colors.main}
                />
              </div>
            </Slide>
            <Slide
              unmountOnExit
              in={shouldShowLinks}
              timeout={300}
              direction="right"
            >
              <div className="bg-tertiary flex items-end p-4">
                <AnimatedLink
                  navigate={() => goToRoute(`/laboratorios`)}
                  title="Laboratorios"
                  description="Revisa los laboratorios virtuales"
                  color="white"
                  hoverColor={Colors.main}
                />
              </div>
            </Slide>
            <Slide
              unmountOnExit
              in={shouldShowLinks}
              timeout={300}
              direction="up"
            >
              <div className="bg-tertiaryLight flex items-end p-4">
                <AnimatedLink
                  navigate={() =>
                    openModalWith(
                      <VideosModal
                        videos={[
                          'https://www.youtube.com/watch?v=4VLbwOGuEuw&',
                        ]}
                      />,
                      'Normal'
                    )
                  }
                  title="¿Cómo hacer tu CV exitoso?"
                  description=""
                  color={Colors.main}
                  hoverColor="white"
                />
              </div>
            </Slide>
            {/* Back button */}
            <Grow
              timeout={300}
              in={['login', 'register', 'curriculum'].includes(
                String(menuSubRoute)
              )}
            >
              <button
                className="absolute top-4 left-4 z-60"
                onClick={() => setMenuSubRoute(null)}
              >
                <i className="fas fa-arrow-circle-left opacity-50 text-white"></i>
              </button>
            </Grow>
            {/* Back button end */}
            {/* Login */}
            <div
              className={
                menuSubRoute === 'login'
                  ? 'span_everything absolute h-full w-full my-auto'
                  : 'hidden'
              }
            >
              <Zoom unmountOnExit in={menuSubRoute === 'login'} timeout={300}>
                <div className="relative w-full h-full flex items-center justify-center">
                  <div className="bg-white w-3/4 rounded-md p-4">
                    <Title type="h2">Inicia sesión</Title>
                    <LoginForm
                      onGoToRegister={() => setMenuSubRoute('register')}
                      onSubmit={onLogin}
                    />
                  </div>
                </div>
              </Zoom>
            </div>
            {/* Login end */}
            {/* Register */}
            <div
              style={{ overflow: 'auto' }}
              className={
                menuSubRoute === 'register'
                  ? 'span_everything w-full py-4'
                  : 'hidden'
              }
            >
              <Zoom
                unmountOnExit
                in={menuSubRoute === 'register'}
                timeout={300}
              >
                <div className="relative md:h-full flex justify-center">
                  <div
                    style={{ overflow: 'auto' }}
                    className="bg-white rounded-md w-3/4 p-4"
                  >
                    <Title type="h2">Crea una cuenta</Title>
                    <RegisterForm
                      onGoToLogin={() => setMenuSubRoute('login')}
                      onSubmit={onRegister}
                    />
                  </div>
                </div>
              </Zoom>
            </div>
            {/* Register end */}
            {/* Upload curriculum */}
            <div
              className={
                menuSubRoute === 'curriculum'
                  ? 'span_everything absolute h-full w-full my-auto py-4 overflow-y-auto'
                  : 'hidden'
              }
            >
              <Zoom
                unmountOnExit
                in={menuSubRoute === 'curriculum'}
                timeout={300}
              >
                <div className="relative w-full h-[110%] md:h-full flex items-center justify-center">
                  <div className="bg-white rounded-md w-3/4 p-4 my-8">
                    <UploadCurriculum />
                  </div>
                </div>
              </Zoom>
            </div>
            {/* Upload curriculum end */}
            {/* Register end */}
          </div>
          {/* Right side */}
          <Slide
            unmountOnExit
            in={showLinks && (!mobile || menuSubRoute === null)}
            timeout={300}
            direction="up"
          >
            <div className="bg-tertiaryLight relative overflow-hidden flex flex-col items-center justify-center md:pb-24 col-start-1 col-end-2 md:col-start-2 md:col-end-3 row-start-1 row-end-2 md:row-end-3">
              {user && (
                <button
                  onClick={signOut}
                  className="absolute top-4 left-4 z-60 text-white"
                >
                  <i className="text-2xl fas fa-sign-out-alt"></i>
                </button>
              )}
              <i className="absolute fas fa-bullseye text-[10em] md:text-[24em] text-gray-100 opacity-10"></i>

              <BetterTooltip
                title="Lee el manual de usuario"
                placement="right-end"
              >
                <button
                  css={css`
                    position: absolute;
                    z-index: 60;
                    top: 1.3em;
                    right: 6em;
                  `}
                >
                  <a href="/assets/ExpolaboralVirtual.pdf" target="_blank">
                    <i className="text-white fas fa-question-circle text-2xl"></i>
                  </a>
                </button>
              </BetterTooltip>

              {/* <BetterTooltip title="Aprende a navegar" placement="right-end">
                <button
                  css={css`
                    position: absolute;
                    z-index: 60;
                    top: 1.3em;
                    right: 10em;
                  `}
                >
                  <button
                    onClick={() => {
                      openModalWith(
                        <Carousel
                          videos={[
                            'https://www.youtube.com/watch?v=AwUGyoaZoKw',
                          ]}
                        />,
                        'Normal'
                      );
                    }}
                  >
                    <i className="text-white fas fa-play-circle text-2xl"></i>
                  </button>
                </button>
              </BetterTooltip> */}

              <div className="absolute z-10 flex flex-col justify-center items-center">
                <h1
                  className={`${
                    mobileSS ? 'text-md' : 'text-4xl'
                  } text-primaryLight mb-4 text-center`}
                >
                  Expo Feria Laboral Virtual
                </h1>
                {/* Action buttons */}
                {!user ? (
                  <div className="flex flex-col">
                    <button
                      onClick={() => setMenuSubRoute('login')}
                      className="bg-white text-primaryLight px-4 py-2 rounded-2xl mb-4"
                    >
                      Iniciar sesión
                    </button>
                    <button
                      onClick={() => setMenuSubRoute('register')}
                      className="bg-primaryLight text-white px-4 py-2 rounded-2xl"
                    >
                      Crear cuenta
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      onClick={() => setMenuSubRoute('curriculum')}
                      className="bg-primaryLight px-4 py-2 text-white rounded-md"
                    >
                      Administrar curriculum
                    </button>
                    <button
                      //disabled={!user}
                      
                      onClick={() => {
                        if (!user?.emailVerified) {
                          return openModalWith(
                            <InformativeModal
                              message="Por favor verifica tu correo antes de entrar al recorrido"
                              type="info"
                            />,
                            'Normal'
                          );
                        }
                         return openModalWith(
                          <InformativeModal
                            message="Gracias por participar"
                            type="info"
                          />,
                           'Normal'
                         );
                        goToRoute('/recorrido');
                      }}
                      className="bg-primaryLight px-4 py-2 text-white rounded-md mt-4"
                    >
                      Gracias por participar 
                    </button>
                  </>
                )}
                {/* End action buttons */}
              </div>
            </div>
            {/* Right side end */}
          </Slide>
          {/* Menu container end */}
        </div>
      </Slide>
      <div className="absolute top-4 right-4 z-[1400] transform scale-75">
        <BurgerBtn onClick={toggleMenu} isOpen={inMenu} />
      </div>
      <Switch>
        <Route exact path="/">
          <Particles id="tsparticles" options={particles} />
          <div className="w-full h-full flex justify-center">
            <BetterTooltip
              title="Lee el manual de usuario"
              placement="right-end"
            >
              <button
                css={css`
                  position: absolute;
                  z-index: 60;
                  top: 1.3em;
                  right: 6em;
                `}
              >
                <a href="/assets/ExpolaboralVirtual.pdf" target="_blank">
                  <i className="text-white fas fa-question-circle text-2xl"></i>
                </a>
              </button>
            </BetterTooltip>
            <img
              css={css`
                z-index: 60;
                position: absolute;
                top: 1em;
                left: 1em;
                height: 5em;

                ${devices.desktopLg} {
                  height: 5em;
                }
              `}
              alt="Logo fime"
              src="/assets/landingpageimages/LogoFimeBlanco.png"
            />
            <div className="flex flex-col items-center justify-center absolute top-1/4 md:top-1/3 z-60">
              <Cogs
                css={css`
                  fill: #22222;
                  opacity: 0.15;
                  overflow: visible;
                  position: absolute;

                  path {
                    transform-origin: center;
                    transform-box: fill-box;
                  }

                  #cog1 {
                    animation: ${rotateConstantly} 6s linear infinite;
                  }

                  #cog2 {
                    animation: ${rotateConstantly} 4s linear infinite;
                  }

                  #cog3 {
                    animation: ${rotateConstantly} 4s linear infinite reverse;
                  }
                `}
              />
              <Slide mountOnEnter timeout={300} in={true} direction="down">
                <h1 className="text-gray-200 text-center text-xl md:text-2xl mx-2">
                  La Facultad de Ingeniería Mecánica Eléctrica te da la
                  bienvenida a la
                </h1>
              </Slide>
              <Slide mountOnEnter timeout={600} in={true} direction="up">
                <div className="w-4/5 text-center">
                  <h1 className="text-primaryLight font-bold text-4xl md:text-7xl mt-1 md:mt-4">
                    Expo Feria Laboral Virtual
                  </h1>
                  <p
                    css={css`
                      max-width: 800px;
                      margin-left: auto;
                      margin-right: auto;
                    `}
                    className="text-gray-200 mt-2 md:mt-12 text-base md:text-lg"
                  >
                    Te invitamos a la Feria Laboral Virtual en la cual podrás
                    interactuar con las diferentes empresas participantes,
                    además tendrás la oportunidad de enviar tu curriculm para
                    así postularte en las vacantes que se ofertan, regístrate y
                    vive la experiencia este 28 de abril.
                  </p>
                  <Button
                    color="default"
                    onClick={toggleMenu}
                    style={{
                      color: 'white',
                      borderColor: 'white',
                      marginTop: '1em',
                    }}
                    variant="outlined"
                  >
                    Comenzar
                  </Button>
                </div>
              </Slide>
            </div>
          </div>
        </Route>
        <Route path="/laboratorios">
          <div className="absolute z-60 top-0">
            <div className="absolute -top-10 -right-10 h-32 md:h-40 w-32 md:w-40 rounded-full bg-tertiary z-60"></div>
            <Suspense fallback={<LoadingScreenA />}>
              <Lab parent={parent} />
            </Suspense>
          </div>
        </Route>
        <Route path="/fime3d">
          <div className="absolute -top-10 -right-10 h-32 md:h-40 w-32 md:w-40 rounded-full bg-tertiary z-60"></div>
          <Suspense fallback={<LoadingScreenA />}>
            <Fime3D />
          </Suspense>
        </Route>
        <Route path="/recorrido">
          {user?.emailVerified ? (
            <>
              <div className="absolute -top-10 -right-10 h-28 md:h-32 w-28 md:w-32 rounded-full bg-tertiary z-60"></div>
              <Suspense fallback={<LoadingScreenA />}>
                <VirtualExperience />
              </Suspense>
            </>
          ) : (
            <Redirect to="/" />
          )}
          {/* <Redirect to="/" /> */}
        </Route>
        <Route path="/empresas">
          <div className="absolute -top-10 -right-10 h-28 md:h-32 w-28 md:w-32 rounded-full bg-tertiary z-60"></div>
          <Suspense fallback={<LoadingScreenA />}>
            <Companies />
          </Suspense>
        </Route>
      </Switch>
    </div>
  );
};

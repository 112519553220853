import { concat, ifElse, isNil, join, map, multiply, not, when } from 'ramda';
import React, { ReactNode } from 'react';

export const ScaleAFrameScale = (scale: string, factor: number) => {
  const scales = scale.split(' ');
  const numbers = map(Number, scales);
  const scaleFn = multiply(factor);
  const scaledNumbers = map(scaleFn, numbers);
  const toAFrameScale = join(' ');
  return toAFrameScale(map(String, scaledNumbers));
};

type AEntityProps = {
  children?: ReactNode[] | ReactNode;
  id?: string;
  geometry?: 'box';
  color?: string;
  scale: string;
  position: string;
  rotation?: string;
  opacity?: string;
  isIcon?: boolean;
  navigatorTo?: string;
  src?: string;
  visible?: boolean;
  light?: Light
  shader?: 'flat'
  handleClick?: () => void;
};

interface Light {
  type:  'point'
  intensity: number
}

export const AEntity = ({
  children,
  color,
  src,
  geometry,
  position,
  rotation,
  scale,
  id,
  opacity = '1',
  isIcon = false,
  visible,
  navigatorTo = '',
  handleClick,
  shader,
  light
}: AEntityProps) => {
  const colorProp = color ? `color: ${color};` : '';
  const srcProp = src ? `src: ${src};` : '';
  let geometryProp = geometry ? `primitive: ${geometry}` : '';
  let materialProps = `side: double; shader: ${shader || 'standard' }; ${colorProp} ${srcProp}; opacity: ${opacity}`;
  let extraProps: any = {};
  if (isIcon) {
    geometryProp = `primitive: plane; height: 1; width: 1;`;
    materialProps = `shader: flat; src: ${src}; alpha-test: 0.23; transparent: true;`;
    const original = scale;
    const scaled = ScaleAFrameScale(scale, 1.1);
    extraProps.animation__mouseenter = `property: scale; to: ${scaled}; startEvents: mouseenter; dur: 100`;
    extraProps.animation__mouseleave = `property: scale; to: ${original}; startEvents: mouseleave; dur: 100`;
  }
  if(light){
    extraProps.light = `type: ${light.type}; intensity: ${light.intensity};`
  }
  // if (navigatorTo) {
  //   extraProps[
  //     'event-set__click'
  //   ] = `_target: #sky; _delay: 300; material.src: #${navigatorTo}`;
  //   extraProps['proxy-event'] = 'event: click; to: #sky; as: fade';
  // }
  return (
    // @ts-ignore
    <a-entity
      visible={visible}
      onClick={handleClick}
      id={id}
      scale={scale}
      position={position}
      material={materialProps}
      geometry={geometryProp}
      rotation={rotation}
      {...extraProps}
    >
      {children}
      {/* @ts-ignore */}
    </a-entity>
  );
};

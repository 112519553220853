import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@flfime/store/store';

export const welcomeScreenSlice = createSlice({
  name: 'welcomescreen',
  initialState: {
    shown: true,
  },
  reducers: {
    fadeOff: (state) => {
      state.shown = false;
    },
  },
});

export const { fadeOff } = welcomeScreenSlice.actions;

export const welcomeScreenIsOn = (state: RootState) =>
  state.welcomescreen.shown;

import { IconButton, Menu, Avatar as MuiAvatar, Fade } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';

interface AvatarProps {
  letter: string;
  items: ReactNode[];
}
export const Avatar = ({ letter, items }: AvatarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const button = e.target as HTMLButtonElement;
    setAnchorEl(button);
  };
  return (
    <div>
      <IconButton size="small" onClick={handleClick}>
        <MuiAvatar>{letter}</MuiAvatar>
      </IconButton>
      <Menu
        style={{
          marginTop: '3em',
        }}
        TransitionComponent={Fade}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
      >
        {items}
      </Menu>
    </div>
  );
};

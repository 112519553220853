import { sleep } from '@flfime/helpers/Sleep';
// const song = require('@flfime/assets/sound/song.mp3');

class Song {
  audio = new Audio('/assets/sound/song.mp3');
  maxVol = 0.1;
  constructor() {
    this.audio.volume = this.maxVol;
    this.audio.loop = true;
  }
  pause = async () => {
    for (let i = this.maxVol * 100; i >= 0; i--) {
      await sleep(100);
      this.audio.volume = this.maxVol * (i / 10);
    }
    this.audio.pause();
  };
  play = async () => {
    this.audio.play();
    for (let i = 0; i < this.maxVol * 100; i++) {
      await sleep(100);
      this.audio.volume = this.maxVol * (i / 10);
    }
  };
}

const music = new Song();

export { music };

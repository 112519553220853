import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { cameraSlice } from '@flfime/store/Camera/slice';
import { chatSlice } from '@flfime/store/Chat/slice';
import { currentUserSlice } from '@flfime/store/CurrentUser/slice';
import { musicSlice } from '@flfime/store/Music/slice';
import { navigationDrawerSlice } from '@flfime/store/NavigationDrawer/slice';
import { notificationsSlice } from '@flfime/store/Notifications/slice';
import { vacanciesSlice } from '@flfime/store/Vacancies/slice';
import { welcomeScreenSlice } from '@flfime/store/WelcomeScreen/slice';
import { navigationSlice } from './Navigation/slice';

const reducers = combineReducers({
  navigation: navigationSlice.reducer,
  camera: cameraSlice.reducer,
  music: musicSlice.reducer,
  welcomescreen: welcomeScreenSlice.reducer,
  chat: chatSlice.reducer,
  notifications: notificationsSlice.reducer,
  navigationDrawer: navigationDrawerSlice.reducer,
  currentUser: currentUserSlice.reducer,
  vacancies: vacanciesSlice.reducer,
});

export const store = configureStore({
  reducer: reducers,
});

store.replaceReducer(reducers);

export type RootState = ReturnType<typeof reducers>;

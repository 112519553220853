import React from 'react';
import { videoLinkToEmbedLink } from '@oop/data';
import styled from '@emotion/styled';
import { getMq } from '../../utilities/utilities';

interface SingleVideoModal {
  rawLink: string;
  modalIsOn: boolean;
}
export const SingleVideoModal: React.FC<SingleVideoModal> = ({
  modalIsOn,
  rawLink,
}) => {
  const src = videoLinkToEmbedLink(rawLink);
  return (
    <RespectRatioDiv>
      <ContainedIFrame title="video" src={src}></ContainedIFrame>
    </RespectRatioDiv>
  );
};

// 16:9
const RespectRatioDiv = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ContainedIFrame = styled.iframe`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  MailOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { Paragraph } from '@flfime/components/Text/Paragraph';
import React, { useState, ReactNode } from 'react';
import {
  Controller,
  useForm,
  Path,
  ControllerRenderProps,
} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { auth } from '@flfime/services/firebase';
import { Colors } from '@flfime/theme/Colors';

type LoginFormProps = {
  onSubmit: (email: string, password: string) => Promise<any>;
  onGoToRegister: () => void;
};

type LoginForm = {
  email: string;
  password: string;
};

export const LoginForm = ({ onSubmit, onGoToRegister }: LoginFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>();
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState({ hasError: false, message: '' });
  const onFormSubmit = async (input: LoginForm) => {
    setFormError({ hasError: false, message: '' });
    try {
      await onSubmit(input.email, input.password);
    } catch (error) {
      console.log(error);
      setFormError({
        hasError: true,
        message: 'Correo o contraseña incorrectos',
      });
    }
    return;
  };

  const [showEmailRecovery, setShowEmailRecovery] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const handleEmailRecoveryClose = () => setShowEmailRecovery(false);
  const sendRecoveryEmail = async () => {
    if (recoveryEmail === '') return;
    await auth.sendPasswordResetEmail(recoveryEmail);
    setShowEmailRecovery(false);
  };

  const emailError = !!errors.email;
  const passwordError = !!errors.password;

  return (
    <div>
      <form className="flex flex-col" onSubmit={handleSubmit(onFormSubmit)}>
        <Paragraph
          className={`mb-2 w-full ${
            formError.message ? 'text-red-500' : 'text-transparent'
          }`}
        >
          {formError.message || 'a'}
        </Paragraph>
        <Controller
          control={control}
          name="email"
          rules={{
            required: {
              value: true,
              message: 'Por favor introduce un correo',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined color="primary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Correo electrónico"
              {...field}
              helperText={
                error ? error?.message : 'Ingresa un correo electrónico'
              }
              error={!!error}
              inputProps={{ 'data-testid': 'login_email' }}
            />
          )}
        />
        <br />
        <Controller
          control={control}
          name="password"
          rules={{
            required: {
              value: true,
              message: 'Por favor introduce una contraseña',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              color="primary"
              size="small"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined color="primary" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      onClick={() => setShowPassword((sp) => !sp)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              label="Contraseña"
              {...field}
              helperText={error ? error?.message : 'Ingresa una contraseña'}
              error={!!error}
              inputProps={{ 'data-testid': 'login_password' }}
            />
          )}
        />
        <button
          type="button"
          onClick={() => setShowEmailRecovery(true)}
          className="mb-2 mt-4 text-right text-gray-500"
        >
          <Paragraph>Olvidé mi contraseña</Paragraph>
        </button>
        <Dialog
          open={showEmailRecovery}
          onClose={setShowEmailRecovery}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Reestablecer contraseña
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Introduce tu dirección de correo electrónico para que podamos
              enviarte un correo de recuperación de contraseña
            </DialogContentText>
            <TextField
              value={recoveryEmail}
              onChange={(e) => setRecoveryEmail(e.target.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Correo"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEmailRecoveryClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={sendRecoveryEmail} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        <br />
        <Button
          style={{ opacity: isSubmitting ? 0.5 : 1, color: 'white' }}
          variant="contained"
          color="primary"
          value="Iniciar sesión"
          onClick={handleSubmit(onFormSubmit)}
          data-testid="login_submit"
        >
          INICIAR SESIÓN
        </Button>
      </form>
      <button
        onClick={onGoToRegister}
        className="mb-2 mt-4 text-right text-gray-500"
      >
        <Paragraph>
          No tienes una cuenta ?
          <span className="text-primary"> Registrate</span>
        </Paragraph>
      </button>
    </div>
  );
};

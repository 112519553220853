import React from 'react';

interface ACameraProps {
  zoom: number;
}
export const ACamera = ({ zoom }: ACameraProps) => {
  return (
    // @ts-ignore
    <a-entity rotation="0 90 0">
      {/* @ts-ignore */}
      <a-camera
        wasd-controls="acceleration:0;"
        rotation="80 80 80"
        position="0 1.6 0"
        zoom={String(zoom)}
      >
        {/* @ts-ignore */}
      </a-camera>
      {/* @ts-ignore */}
    </a-entity>
  );
};

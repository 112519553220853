import React, { useEffect, useState } from 'react';
import { AEntity } from '@oop/ui';
import { useDispatch, useSelector } from 'react-redux';
import { sleep } from '@oop/data';
import { FileName } from '@oop/data';

type NavigationEntityProps = {
  position: string;
  rotation: string;
  type: 'hotspot' | 'arrow';
  id?: string;
  to: FileName;
  directSrc?: string;
  onClick: (to: FileName) => any;
};
export const NavigationEntity = ({
  position,
  rotation,
  type,
  id,
  to,
  onClick,
}: NavigationEntityProps) => {
  const src = type === 'arrow' ? '#arrow' : '#hotspot';

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      // To avoid seeing the white square, don't really know if it works
      // because of this or bc of the browser cache o.O
      setVisible(true);
    }, 150);
  }, []);
  return (
    <AEntity
      visible={visible}
      navigatorTo={to}
      handleClick={() => onClick(to)}
      id={id}
      position={position}
      rotation={rotation}
      src={src}
      isIcon
      scale="1 1 1"
    />
  );
};

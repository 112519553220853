import { ssToData } from '@flfime/helpers/Misc';
import {
  postulationsCollection,
  vacanciesCollection,
} from '@flfime/services/firebase';
import { UserCredential } from '@flfime/types/FirebaseTypes';
import { Vacancy } from '@flfime/types/StandInformationTypes';

export class VacanciesAPI {
  static async postVacancy({
    curriculum,
    email,
    phone,
    name,
    companyId,
    vacancyId,
  }: {
    curriculum: string;
    phone: string;
    email: string;
    name: string;
    companyId: string;
    vacancyId: string;
  }) {
    await postulationsCollection(companyId, vacancyId).add({
      curriculum,
      estatus: 1,
      fecha_hora: new Date(),
      usuario: {
        celular: phone,
        email,
        nombre: name,
      },
    });
  }
  static async getVacancies({
    companyId,
    email,
  }: {
    companyId: string;
    email: string;
  }) {
    const ss = await vacanciesCollection(companyId).get();
    const vacanciesFF = ssToData({ idField: 'vacancyId', ss });
    const vacancies = vacanciesFF.map((v) => ({
      vacancyId: v.vacancyId,
      benefits: v.beneficios,
      careers: v.carreras.map((c: any) => c.nombre),
      description: v.descripcion,
      name: v.nombre,
      salary: v.salario,
      schedule: v.horario,
    })) as Vacancy[];
    const fetchPostulation = (v: Vacancy) =>
      postulationsCollection(companyId, v.vacancyId)
        .where('usuario.email', '==', email)
        .get();
    const docs = await Promise.all(vacancies.map(fetchPostulation));
    let vacanciesWithPostulation = vacancies.map((v, i) => ({
      ...v,
      curriculumSent: !docs[i].empty,
    }));
    return { vacancies: vacanciesWithPostulation, companyId };
  }
}

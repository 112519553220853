import React, { ReactNode } from 'react';

type ASceneProps = { children: ReactNode[] | ReactNode; embedded?: boolean };

export const AScene = ({ children, embedded = false }: ASceneProps) => {
  return (
    // @ts-ignore
    <a-scene
      embedded={embedded}
      width="400px"
      height="400px"
      cursor="fuseTimeout: 0; rayOrigin: mouse;"
      inspector="https://cdn.jsdelivr.net/gh/aframevr/aframe-inspector@master/dist/aframe-inspector.min.js"
    >
      {children}
      {/* @ts-ignore */}
    </a-scene>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@flfime/store/store';

export const navigationDrawerSlice = createSlice({
  name: 'navigationdrawer',
  initialState: {
    open: false,
  },
  reducers: {
    toggleNavigationDrawer: (state) => {
      state.open = !state.open;
    },
  },
});

export const { toggleNavigationDrawer } = navigationDrawerSlice.actions;

export const navigationDrawerIsOpen = (state: RootState) =>
  state.navigationDrawer.open;

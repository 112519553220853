import { FileName } from '@oop/data';

export type Renders =
  | 'render0000'
  | 'render0001'
  | 'render0002'
  | 'render0003'
  | 'render0004'
  | 'render0005'
  | 'render0006'
  | 'render0007'
  | 'render0008'
  | 'render0009'
  | 'render0010'
  | 'render0011'
  | 'render0012'
  | 'render0013'
  | 'render0014'
  | 'render0015'
  | 'render0016'
  | 'render0017'
  | 'render0018'
  | 'render0019'
  | 'render0020'
  | 'render0021'
  | 'render0022'
  | 'render0023'
  | 'render0024'
  | 'render0025'
  | 'render0026'
  | 'render0027'
  | 'render0028'
  | 'render0029'
  | 'render0030'
  | 'render0031'
  | 'render0032'
  | 'render0033'
  | 'render0034'
  | 'render0035'
  | 'render0036'
  | 'render0037'
  | 'render0038'
  | 'render0039'
  | 'render0040'
  | 'render0041'
  | 'render0042'
  | 'render0043'
  | 'render0044'
  | 'render0045'
  | 'render0046'
  | 'render0047'
  | 'render0048';

const buildRFNs = <T extends Record<string, Renders>>(t: T) => {
  return t;
};

export const RFNs = buildRFNs({
  // RenderFileNames
  r0: 'render0000',
  r1: 'render0001',
  r2: 'render0002',
  r3: 'render0003',
  r4: 'render0004',
  r5: 'render0005',
  r6: 'render0006',
  r7: 'render0007',
  r8: 'render0008',
  r9: 'render0009',
  r10: 'render0010',
  r11: 'render0011',
  r12: 'render0012',
  r13: 'render0013',
  r14: 'render0014',
  r15: 'render0015',
  r16: 'render0016',
  r17: 'render0017',
  r18: 'render0018',
  r19: 'render0019',
  r20: 'render0020',
  r21: 'render0021',
  r22: 'render0022',
  r23: 'render0023',
  r24: 'render0024',
  r25: 'render0025',
  r26: 'render0026',
  r27: 'render0027',
  r28: 'render0028',
  r29: 'render0029',
  r30: 'render0030',
  r31: 'render0031',
  r32: 'render0032',
  r33: 'render0033',
  r34: 'render0034',
  r35: 'render0035',
  r36: 'render0036',
  r37: 'render0037',
  r38: 'render0038',
  r39: 'render0039',
  r40: 'render0040',
  r41: 'render0041',
  r42: 'render0042',
  r43: 'render0043',
  r44: 'render0044',
  r45: 'render0045',
  r46: 'render0046',
  r47: 'render0047',
  r48: 'render0048',
});

import { VideosModal } from '@flfime/features/Videos/components/VideosModal/VideosModal';
import React, { createContext, useRef, useState } from 'react';
import { ReactNode } from 'react';
import { Transition } from 'react-transition-group';

export const ModalContext = createContext({
  openModalWith: (c: ReactNode, mt: ModalTypes) => {},
  closeModal: () => {},
  isOn: false,
});
type ModalTypes = 'Long' | 'Normal';
type ModalProviderProps = { children: ReactNode | ReactNode[] };
export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOn, setIsOn] = useState(false);
  const [modalType, setModalType] = useState<ModalTypes>('Normal');
  const opaqueBg = useRef<HTMLDivElement>(null);
  const animamtedDiv = useRef<HTMLDivElement>(null);
  const [componentToShow, setComponentToShow] = useState<ReactNode>(<></>);
  const openModalWith = (child: ReactNode, modalType: ModalTypes) => {
    const body = document.body;
    body.style.top = `-${window.scrollY}px`;
    body.style.position = 'fixed';
    setModalType(modalType);
    setComponentToShow(child);
    setIsOn(true);
  };
  const closeModalFromInternalSrc = (e: React.MouseEvent) => {
    if (e.target != opaqueBg.current && e.target != animamtedDiv.current)
      return;
    else closeModal();
  };
  const closeModal = () => {
    const body = document.body;
    const scrollY = document.body.style.top;
    body.style.position = '';
    body.style.top = '';
    body.style.height = 'auto';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    setIsOn(false);
  };
  const transitionStyles = {
    entering: { backgroundColor: '#22222200' },
    entered: { backgroundColor: '#22222280' },
    exiting: { backgroundColor: '#22222200' },
    exited: { backgroundColor: '#22222200', display: 'none' },
  };

  const modalLongClasses = modalType === 'Long' ? 'my-4 md:my-20' : '';
  const modalNormalClasses =
    modalType === 'Normal' ? 'flex items-center justify-center h-screen' : '';

  return (
    <ModalContext.Provider value={{ closeModal, openModalWith, isOn }}>
      <Transition
        timeout={{
          appear: 500,
          enter: 0,
          exit: 500,
        }}
        in={isOn}
      >
        {(state) => (
          <div
            ref={opaqueBg}
            onClick={(e) => closeModalFromInternalSrc(e)}
            className={`w-screen h-screen overflow-y-auto px-2 bg-black fixed top-0 transition-all duration-1000`}
            style={{
              // @ts-ignore
              ...transitionStyles[state],
              zIndex: 70,
            }}
            data-testid="modal_bg"
          >
            <div
              ref={animamtedDiv}
              id="modal_container"
              className={`${
                isOn ? 'opacity-100' : 'opacity-0'
              } transition-all relative z-20 duration-200 ${modalLongClasses} ${modalNormalClasses} mx-auto`}
            >
              {componentToShow}
            </div>
          </div>
        )}
      </Transition>
      <div className={`z-40 bg-white ${isOn ? '' : ''}`}>{children}</div>
    </ModalContext.Provider>
  );
};

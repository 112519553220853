import { RegisterForm } from '@flfime/components/Authentication/Register/RegisterForm';
import {
  auth,
  curriculumsRef,
  storage,
  usersCollection,
} from '@flfime/services/firebase';
import { CurrentUser } from '@flfime/types/CurrentUser';
import { UserCredential } from '@flfime/types/FirebaseTypes';

class AuthenticationAPI {
  async registerUser({
    email,
    fullName,
    password,
    phoneNumber,
    ...info
  }: RegisterForm) {
    const userCred = await auth.createUserWithEmailAndPassword(email, password);
    const { user } = userCred;
    await user?.updateProfile({ displayName: fullName });
    await user?.sendEmailVerification();
    delete info['confirmPassword'];
    const currentUser = {
      curriculum: null,
      displayName: fullName,
      email: email,
      // matricula: matricula,
      uid: user?.uid,
      celular: phoneNumber,
    } as CurrentUser;
    const data = {
      ...currentUser,
      ...info,
    };
    console.log('data', data);
    await usersCollection.doc(user?.uid).set(data);
    return { userCred, currentUser };
  }
  async loginUser({ email, password }: { email: string; password: string }) {
    const userCred = await auth.signInWithEmailAndPassword(email, password);
    const currentUser = await this.getCurrentUserInfo(
      userCred.user?.uid as string
    );
    return { currentUser, userCred };
  }
  async getCurrentUserInfo(uid: string) {
    return (await usersCollection.doc(uid).get()).data() as CurrentUser;
  }
  async getUserCurriculumURL(ref: string) {
    return await curriculumsRef(ref).getDownloadURL();
  }
  async updateUserCurriculum(ref: string, uid: string) {
    await usersCollection.doc(uid).update({
      curriculum: ref,
    });
  }
  async uploadCurriculum(
    data: string,
    uid: string,
    name: string,
    matricula: string
  ) {
    const fileName = `${name}_${matricula}.pdf`;
    await curriculumsRef(fileName).putString(data, 'data_url');
    this.updateUserCurriculum(fileName, uid);
    return fileName;
  }
}

const AuthenticationRepo = new AuthenticationAPI();

export { AuthenticationRepo };

import { Theme, ThemeProvider } from '@emotion/react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { Colors } from './Colors';

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.main,
      light: Colors.lightMain,
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.lightSecondary,
    },
  },
});

export const theme: Theme = {
  fontFamily: 'Montserrat, Arial',
  color: {
    primary: Colors.main,
    primaryLight: Colors.lightMain,
    secondary: Colors.secondary,
    secondaryLight: Colors.lightSecondary,
    tertiary: '#313b48',
    tertiaryLight: '#28313d',
  },
  contentSpacing: {
    sm: '1em',
    md: '10em',
    lg: '20em',
    xl: '20em',
  },
  devices: {
    mobileSm: '@media (min-width: 310px)',
    mobileMd: '@media (min-width: 350px)',
    mobileLg: '@media (min-width: 425px)',
    tablet: '@media (min-width: 768px)',
    laptop: '@media (min-width: 1024px)',
    desktop: '@media (min-width: 1330px)',
    desktopLg: '@media (min-width: 1440px)',
  },
};

export const mq = theme.devices;

export const WithThemeProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </ThemeProvider>
  );
};

import { Timestamp } from '@firebase/firestore-types'

export type Chat = {
    chatId?: string
    userId: string
    companyId: string
    companyName: string    
    updated: string | Timestamp | Date
    username: string
    clientPendingMessages?: number
    userPendingMessages?: number
    latestMessage?: string
} 

export type Message = {
    messageId?: string
    chatId: string
    createdAt: string | Timestamp | Date
    idAuthor: string
    message: string
}

export const ChatFields = {
    chatId: 'chatId',
    userId: 'userId',
    companyId: 'companyId',
    updated: 'updated',
    username: 'username'
}

export const MessageFields = {
    messageId: 'messageId',
    chatId: 'chatId',
    createdAt: 'createdAt',
    idAuthor: 'idAuthor',
    message: 'message'
}
import React from 'react';
// import 'aframe';
// import 'aframe-chromakey-material';
import '@flfime/styles/extra.css';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { Colors } from '@flfime/theme/Colors';
import { ModalProvider } from '@flfime/components/Modal/ModalCtx';
import { NavigationDrawer } from '@flfime/components/NavigationDrawer/NavigationDrawer';
import { Home2 } from '@flfime/pages/Home/Home2';

const useStyles = makeStyles({
  info: {
    backgroundColor: `${Colors.main} !important`,
  },
  root: {
    zIndex: 1000,
  },
});

export const NavbarRoutes = [
  {
    routeName: 'Inicio',
    path: '/inicio',
  },
  {
    routeName: 'Fime3D',
    path: '/fime3d',
  },
  {
    routeName: 'Laboratorios',
    path: '/laboratorios',
  },
];

const App = () => {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      dense={mobile}
      classes={{
        variantSuccess: classes.info,
        containerAnchorOriginBottomRight: classes.root,
      }}
    >
      <ModalProvider>
        <NavigationDrawer />
        <BrowserRouter>
          <Home2 />
        </BrowserRouter>
      </ModalProvider>
    </SnackbarProvider>
  );
};

export default App;

import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { auth } from '@flfime/services/firebase';
import { User } from '@firebase/auth-types';
import { LoadingScreenA } from '@oop/ui';
import { useDispatch } from 'react-redux';
import { AuthenticationRepo } from '@flfime/services/Authentication/AuthenticationAPI';
import { setCurrentUser as setCurrentUserInStore } from '@flfime/store/CurrentUser/slice';

export const AuthContext = createContext<User | null>(null);

type AuthProviderProps = {
  children: ReactNode | ReactNode[];
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const dispatch = useDispatch();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log(user);
        const userCreds = await AuthenticationRepo.getCurrentUserInfo(user.uid);
        dispatch(setCurrentUserInStore(userCreds));
      }
      setCurrentUser(user);
      setPending(false);
    });
  }, []);
  if (pending) {
    return <LoadingScreenA />;
  }
  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
}

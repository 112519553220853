import React, { useContext, useEffect, useState } from 'react';
import { map } from 'ramda';
import { extractYoutubeId, videoIDToEmbedLink } from '@oop/data';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import SweipeableViews from 'react-swipeable-views';
import { Dot } from '@flfime/components/Dot/Dot';
import { ModalContext } from '@flfime/components/Modal/ModalCtx';

type CarouselProps = {
  videos: string[];
};

const song = new Audio('/assets/audio.mp3');
song.volume = 0.2;

export const Carousel = ({ videos }: CarouselProps) => {
  const [index, setIndex] = useState(0);
  const totalVideos = videos.length;
  const resetVideos = () => {
    const els = document.querySelectorAll(
      '.iframes-container iframe'
    ) as unknown;
    const iframes = els as HTMLIFrameElement[];
    iframes.forEach((ifr) => {
      ifr.contentWindow.postMessage(
        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
        '*'
      );
    });
  };

  const { isOn } = useContext(ModalContext);

  useEffect(() => {
    song.play();
    console.log(song);
  }, []);

  useEffect(() => {
    if (!isOn) {
      resetVideos();
    }
  }, [isOn]);

  const prev = () => {
    resetVideos();
    setIndex((i) => (i === 0 ? totalVideos - 1 : i - 1));
  };
  const next = () => {
    resetVideos();
    setIndex((i) => (i === totalVideos - 1 ? 0 : i + 1));
  };
  const goto = (i: number) => () => {
    resetVideos();
    setIndex(i);
  };

  const ids = map(extractYoutubeId, videos);
  console.log(ids);
  const embedURLS = map(videoIDToEmbedLink, ids);
  const idToYTIFrame = (embedURL: string) => (
    <div className="relative" style={{ paddingTop: '56.25%' }}>
      <iframe
        className="top-0 left-0 absolute w-full h-full"
        src={embedURL}
      ></iframe>
    </div>
  );
  const iframes = map(idToYTIFrame, embedURLS);
  const dots = iframes.map((_, i) => {
    return <Dot className="mx-2" selected={i === index} onClick={goto(i)} />;
  });

  return (
    <div className="iframes-container relative flex justify-center w-full">
      <div className="absolute z-50 top-1/2 left-2 transform -translate-y-1/2">
        <IconButton onClick={prev}>
          <ArrowLeft fontSize="large" style={{ color: 'white' }} />
        </IconButton>
      </div>
      <div className="absolute z-50 top-1/2 right-2 transform -translate-y-1/2">
        <IconButton onClick={next}>
          <ArrowRight fontSize="large" style={{ color: 'white' }} />
        </IconButton>
      </div>
      <div className="absolute z-50 -bottom-16 transform -translate-y-1/2">
        {dots}
      </div>
      <SweipeableViews className="w-96 md:w-160" index={index}>
        {iframes}
      </SweipeableViews>
    </div>
  );
};

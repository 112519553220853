import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { always, compose, lensProp, map, over, toString } from 'ramda';
import { normalizeChatDate } from '@flfime/services/Chat/ChatAPI';
import { Chat, Message } from '@flfime/types/Chat';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/pipeable';
import { flow } from 'fp-ts/lib/function';

const createdAtLens = lensProp<Message>('createdAt');
const userPendingMessagesLens = lensProp<Chat, 'userPendingMessages'>(
  'userPendingMessages'
);
export const normalizeMessageDate = over(createdAtLens, toString);

type chatSliceState = {
  isOpen: boolean;
  chats: Chat[];
  messages: Message[];
};
export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    isOpen: false,
    chats: [],
    messages: [],
  } as chatSliceState,
  reducers: {
    toggleChat: (state) => {
      state.isOpen = !state.isOpen;
    },
    addChats: (state, { payload: chats }: PayloadAction<Chat[]>) => {
      const notAddedChats = chats.filter(
        (c) => !state.chats.find((sc) => sc.chatId === c.chatId)
      );
      state.chats = [...state.chats, ...map(normalizeChatDate, notAddedChats)];
    },
    updatePendingMessages: (
      state,
      {
        payload: { standid, userPendingMessages },
      }: PayloadAction<{ standid: string; userPendingMessages: number }>
    ) => {
      const forChat: O.Option<Chat> = O.of(
        state.chats.find((c) => c.companyId === standid)
      );
      pipe(
        forChat,
        O.map((c) => (c.userPendingMessages = userPendingMessages))
      );
    },
    addMessages: (state, { payload: messages }: PayloadAction<Message[]>) => {
      const notAddedMessages = messages.filter(
        (m) => !state.messages.find((sm) => sm.messageId === m.messageId)
      );
      state.messages = [
        ...state.messages,
        ...map(normalizeMessageDate, notAddedMessages),
      ];
    },
  },
});

export const {
  toggleChat,
  addChats,
  addMessages,
  updatePendingMessages,
} = chatSlice.actions;

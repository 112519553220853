import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@flfime/store/store';
import { CurrentUser } from '@flfime/types/CurrentUser';

export const currentUserSlice = createSlice({
  name: 'currentuser',
  initialState: { currentUser: null as CurrentUser | null },
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<CurrentUser>) => {
      state.currentUser = payload;
    },
    setCurrentUserCurriculum: (state, { payload }: PayloadAction<string>) => {
      state.currentUser!.curriculum = payload;
    },
  },
});

export const {
  setCurrentUser,
  setCurrentUserCurriculum,
} = currentUserSlice.actions;

export const currentUser = (state: RootState) => state.currentUser.currentUser;

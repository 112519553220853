import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

type OnlyPublicRouteProps = {
  path: string;
  exact?: boolean;
  shouldAccess: boolean;
  redirectTo: string;
};

export const OnlyPublicRoute: React.FC<OnlyPublicRouteProps> = ({
  path,
  exact,
  shouldAccess,
  redirectTo,
  children,
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        shouldAccess ? <Redirect to={redirectTo} /> : children
      }
    />
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarKey, SnackbarProps, VariantType } from 'notistack';
import { compose, filter, not, propEq } from 'ramda';
import { RootState } from '@flfime/store/store';

export type Notification = {
  message: string;
  variant: VariantType;
  key: SnackbarKey;
  dismissed: boolean;
};
type notificationsSliceState = {
  notifications: Notification[];
};
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
  } as notificationsSliceState,
  reducers: {
    enqueueSnackbarGlobal: (state, action: PayloadAction<Notification>) => {
      state.notifications = [...state.notifications, action.payload];
    },
    closeSnackbarGlobal: (state, action: PayloadAction<SnackbarKey>) => {
      const notiToDismiss = action.payload;
      state.notifications = state.notifications.map<Notification>((n) =>
        notiToDismiss === n.key ? { ...n, dismissed: true } : n
      );
    },
    removeSnackbarGlobal: (state, action: PayloadAction<SnackbarKey>) => {
      const notiToRemove = action.payload;
      const removeNoti = compose<Notification, boolean, boolean>(
        not,
        propEq('key', notiToRemove)
      );
      state.notifications = filter(removeNoti, state.notifications);
    },
  },
});

export const {
  closeSnackbarGlobal,
  enqueueSnackbarGlobal,
  removeSnackbarGlobal,
} = notificationsSlice.actions;

export const notifications = (state: RootState) =>
  state.notifications.notifications;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LazyLoadRenders, SceneConfig } from '@oop/data';
import { either, equals, ifElse, isNil, uniq, when } from 'ramda';
import {
  FileName,
  FileNames,
} from 'libs/data/src/lib/VirtualExperienceTypes/SceneConfig';

type RenderLoadInfo = {
  render: FileName;
  fullyLoaded: boolean;
};

type navigationSliceState = {
  currentRender: FileName;
  loadedRenders: RenderLoadInfo[];
  isTransitioning: boolean;
};

export const createNavigationSlice = (
  initialState: navigationSliceState,
  lastLocation: FileName,
  SceneConfigs: Record<string, SceneConfig<any>>,
  RFNs: FileNames
) => {
  return createSlice({
    name: 'navigation',
    initialState: initialState as navigationSliceState,
    reducers: {
      goTo: (state, action: PayloadAction<{ render: FileName }>) => {
        localStorage.setItem('lastLocation', action.payload.render);
        const to = action.payload.render;
        const toConfig = SceneConfigs[to];
        if (toConfig && toConfig.cielo !== '') {
          state.currentRender = action.payload.render;
        }
        state.loadedRenders = uniq([
          ...state.loadedRenders,
          ...LazyLoadRenders(
            state.currentRender,
            state.loadedRenders.map((lr) => lr.render),
            SceneConfigs,
            2
          ).map((r) => ({ render: r, fullyLoaded: false })),
        ]);
      },
      setRenderLoaded: (
        state,
        { payload: render }: PayloadAction<FileName>
      ) => {
        const r = state.loadedRenders.find((lr) => lr.render === render);
        if (!r) return;
        r.fullyLoaded = true;
      },
      setIsTransitioning: (state, action: PayloadAction<boolean>) => {
        state.isTransitioning = action.payload;
      },
    },
  });
};

type State = ReturnType<ReturnType<typeof createNavigationSlice>['reducer']>;
type Reducer = {
  navigation: State;
};

export const currentRender = <T extends Reducer>(state: T) =>
  state.navigation.currentRender;

export const loadedRenders = <T extends Reducer>(state: T) =>
  state.navigation.loadedRenders;

export const isTransitioning = <T extends Reducer>(state: T) =>
  state.navigation.isTransitioning;

export const currentRenderIsFullyLoaded = <T extends Reducer>(state: T) => {
  const r = state.navigation.loadedRenders.find(
    (lr) => lr.render === state.navigation.currentRender
  );
  if (!r) return false;
  return r.fullyLoaded;
};

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { getColor } from '../../utilities/utilities';

const pulse = keyframes`
  0% {
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
  }
`;

export const PulseSpinner = styled.div`
    margin: 100px auto;
    width: 100px;
    height: 100px;
    background-color: ${getColor('primaryLight')};
    opacity: 0.3;
    border-radius: 100%;
    animation: ${pulse} 1s infinite ease-in-out;
  }
`;

import React, { ReactNode } from 'react';

type TitleProps = {
  className?: string;
  children: ReactNode[] | ReactNode;
  type: 'h1' | 'h2' | 'h3';
};
export const Title = ({ className, children, type }: TitleProps) => {
  if (type === 'h1')
    return <h1 className={`${className} text-lg md:text-xl`}>{children}</h1>;
  if (type === 'h2')
    return <h1 className={`${className} text-base md:text-lg`}>{children}</h1>;
  return <h1 className={`${className} text-sm md:text-base`}>{children}</h1>;
};

import React from 'react';
import { AText, AEntity } from '@oop/ui';
import { useTheme } from '@emotion/react';

type TotemProps = {
  position: string;
  rotation: string;
  icons: [IconConfig, IconConfig];
};

export type IconConfig = {
  iconID: string;
  handleClick: () => void;
  title: string;
};

export const Totem2Rows = ({ position, rotation, icons }: TotemProps) => {
  const YScale = 0.2588;
  const XScale = 0.7;
  const iconPosition = (y: string) => `0 ${y} 0.7`;
  const firstIconY = 0.2;
  const gapBetweenIconYs = 0.44;
  const iconScale = `${XScale} ${YScale} ${XScale}`;
  const theme = useTheme();

  const IconEntities = icons.map((config, index) => (
    <>
      <AText
        align="center"
        color="#333333"
        scale="0.8 0.4 1"
        text={config.title}
        position={iconPosition(
          String(firstIconY - index * gapBetweenIconYs + 0.2)
        )}
      />
      <AEntity
        handleClick={config.handleClick}
        key={config.iconID + String(Math.random())}
        src={config.iconID}
        position={iconPosition(String(firstIconY - index * gapBetweenIconYs))}
        scale={iconScale}
        isIcon={true}
      />
    </>
  ));

  return (
    <AEntity
      rotation={rotation}
      id="main_totem"
      color="white"
      geometry="box"
      scale="0.368 1 0.1"
      position={position}
    >
      <AEntity
        id="main_totem_decoration"
        color={theme.color.primary}
        geometry="box"
        scale="0.1 1 0.1"
        position="-0.52 0 0.5"
      />
      {IconEntities}
    </AEntity>
  );
};

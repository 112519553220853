import React, { ReactNode } from 'react';

type ParagraphProps = {
  className?: string;
  size?: 'Normal' | 'Small';
  children: ReactNode;
};

export const Paragraph = ({
  className,
  size = 'Normal',
  children,
}: ParagraphProps) => {
  return (
    <p
      className={`${className} ${size === 'Normal' && 'text-sm md:text-md'} ${
        size === 'Small' && 'text-xs md:text-sm'
      }`}
    >
      {children}
    </p>
  );
};
